import { useState } from "react";
import axios from "../../utils/axios";
import { useSearchParams, useNavigate } from "react-router-dom";
const SetNewPassword = () => {
  const [password, setPassword] = useState("");
  const [passwordConfirm, setConfirmPassword] = useState("");

  const [errMsg, setErrMsg] = useState("");
  const [confMsg, setConfMsg] = useState("");

  const [searchParams, setSearchParams] = useSearchParams();

  let navigate = useNavigate();

  let resetToken = searchParams.get("token");

  const handleUpdateSubmit = (e) => {
    e.preventDefault();

    if (password !== passwordConfirm) {
      setErrMsg("Passwords do not match");
    } else {
      setErrMsg("");
      let url = "/users/reset";
      let data = { token: resetToken, password };
      axios
        .post(url, data)
        .then((res) => {
          setConfMsg(res.data.message);
          setTimeout(() => {
            navigate("/", { replace: true });
          }, 1000);
        })
        .catch((err) => {});
    }
  };

  return (
    <div className="container">
      <div
        className="mt-5"
        style={{ width: "30rem", height: "10rem", margin: "auto" }}
      >
        <h1 className="text-center mt-5">Ponty Safety</h1>
        <form
          onSubmit={handleUpdateSubmit}
          className="mx-2"
          style={{ marginTop: "10rem" }}
        >
          <div>
            <h6 className="text-center">
              <strong>Forgot password</strong>
            </h6>
            <div
              className={confMsg ? "confmsg" : "offscreen"}
              aria-live="assertive"
            >
              {confMsg}
            </div>

            <div
              className={errMsg ? "errmsg" : "offscreen"}
              aria-live="assertive"
            >
              {errMsg}
            </div>
            <label htmlFor="password">Password</label>
            <input
              type="password"
              id="password"
              className="form-control"
              value={password}
              onChange={(e) => {
                setPassword(e.target.value);
              }}
            />
            <label htmlFor="confirmpassword">Confirm Password</label>
            <input
              type="password"
              id="confirmpassword"
              className="form-control"
              value={passwordConfirm}
              onChange={(e) => {
                setConfirmPassword(e.target.value);
              }}
            />
          </div>
          <div>
            <input
              type="submit"
              className="form-control btn btn-warning my-2"
              value="Reset"
              disabled={confMsg ? true : false}
            />
          </div>
        </form>
      </div>
    </div>
  );
};

export default SetNewPassword;
