import { createSlice } from "@reduxjs/toolkit";

const initialStateValue = {
  data: [],
  errMsg: "",
};

export const incidentSlice = createSlice({
  name: "incidents",
  initialState: { value: initialStateValue },
  reducers: {
    //actions
    getIncidents: (state, action) => {
      state.value.data = action.payload;
    },
    setErrMsg: (state, action) => {
      state.value.errMsg = action.payload;
    },
    logoutIncidents: (state) => {
      state.value = initialStateValue;
    },
  },
});
export const { getIncidents, setErrMsg, logoutIncidents } =
  incidentSlice.actions;
export const selectIncident = (state) => state.incidents;
export default incidentSlice.reducer;
