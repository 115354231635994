import React from "react";
import { useEffect } from "react";

import Navbar from "../Navbar";
import Table from "./Table";
import axios from "../../utils/axios";
import { useDispatch } from "react-redux";
import { getRoles, setErrMsg } from "../../reducers/roles";

const Users = () => {
  const dispatch = useDispatch();
  const roleUrls = "/users/roles/all";

  useEffect(() => {
    axios
      .get(roleUrls)
      .then((roles) => {
        dispatch(getRoles(roles.data));
      })
      .catch((err) => {
        dispatch(setErrMsg(err.error));
      });
  });

  return (
    <div>
      <Navbar Active="users" />
      <div className="mt-1 container table-responsive">
        <Table />
      </div>
    </div>
  );
};

export default Users;
