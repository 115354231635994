import Login from "./Login";
import Incidents from "./incidents/Incidents";
import Drivers from "./drivers/Drivers";
import ResetPassword from "./reset/ResetPassword";
import SetNewPassword from "./reset/SetNewPassword";
import NotFound from "./NotFound";
import ProtectedRoutes from "./ProtectedRoutes";
import Audit from "./audit/Audit";
import View from "./drivers/View";
import { Routes, Route } from "react-router-dom";
import ManageAudit from "./audit/ManageAudit";
import ManageIncident from "./incidents/ManageIncidents";
import Users from "./users/Users";
import Dashboard from "./dashboard/Dashboard";
import ReportGrid from "./report/report";
import SingleAudit from "./audit/SingleAudit";
import React, { useEffect, useState } from "react";

function Views() {
  const [innerSize, setInnerSize] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setInnerSize(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  return (
    <Routes>
      <Route path="/" element={<Login />} />
      <Route path="/reset" element={<ResetPassword />} />
      <Route path="/user/reset/" element={<SetNewPassword />} />

      <Route element={<ProtectedRoutes allowedRoles={[1, 2]} />}>
        <Route path="/home" element={<Dashboard />} />
        <Route path="/drivers" element={<Drivers />} />
        <Route path="/driversIncidents" element={<View />} />
        <Route path="/audit" element={<Audit />} />
        <Route path="/incidents" element={<Incidents />} />
        <Route path="/report" element={<ReportGrid />} />

        <Route path="/audit/:id" element={<SingleAudit />} />
      </Route>
      <Route element={<ProtectedRoutes allowedRoles={[1]} />}>
        <Route path="/incidents/manage" element={<ManageIncident />} />
        <Route path="/audit/manage" element={<ManageAudit />} />
        <Route path="/personnel" element={<Users />} />
      </Route>
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
}

export default Views;
