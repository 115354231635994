import Navbar from "../Navbar";
import { Modal, Button } from "react-bootstrap";
import { useState, useEffect, useCallback } from "react";
import axios from "../../utils/axios";
import { getAudits } from "../../reducers/audit";
import { useDispatch, useSelector } from "react-redux";
import { setErrMsg } from "../../reducers/incidents";
import AddAuditForm from "./AddAuditForm";
import EditAuditForm from "./EditAuditForm";
import Paginator from "../pagination/Paginator";
import { selectPage, selectLimit, setPages } from "../../reducers/pagination";
import { useNavigate } from "react-router-dom";
// import NavbarResponsive from "./NavbarResponsive";
import Card from "react-bootstrap/Card";

import { Form } from "react-bootstrap";
import { exportReport } from "../report/export.service";
import {
  filterAudits,
  fetchAuditCategories,
  fetchAuditOrganization,
  fetchAuditStatus,
  fetchAuditMeasures,
  fetchAuditTypes,
} from "./audit.service";

const ModalAudit = (props) => {
  return (
    <Modal show={props.show}>
      <Modal.Header>
        <Modal.Title id="contained-modal-title-vcenter">New Audit</Modal.Title>
        <Button variant="danger" type="submit" onClick={props.close}>
          Close
        </Button>
      </Modal.Header>
      <Modal.Body>
        <AddAuditForm close={props.close} />
      </Modal.Body>
    </Modal>
  );
};

const Audit = () => {
  const user = JSON.parse(localStorage.getItem("user"));
  const [modalShow, setModalShow] = useState(false);
  const [editModalShow, setEditModalShow] = useState(false);

  const [currentAudit, setEditAudit] = useState("");
  const dispatch = useDispatch();
  let navigate = useNavigate();

  const openManagement = () => {
    navigate("manage", { replace: false });
  };
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [filterCategory, setFilterCategory] = useState("");
  const [filterOrganization, setFilterOrganization] = useState("");
  const [filterStatus, setFilterStatus] = useState("");

  const [auditCategories, setAuditCategories] = useState([]);
  const [auditMeasures, setAuditMeasures] = useState([]);
  const [auditTypes, setAuditTypes] = useState([]);
  const [auditOrganizations, setAuditOrganizations] = useState([]);
  const [auditStatuses, setAuditStatuses] = useState([]);
  const [audits, setAudits] = useState(
    useSelector((state) => state.audits.value.data)
  );

  const page = useSelector(selectPage);
  const records = useSelector(selectLimit);

  useEffect(() => {
    let params = {
      from: fromDate,
      to: toDate,
      category: filterCategory,
      organization: filterOrganization,
      status: filterStatus,
      limit: records,
      page: page - 1,
    };
    filterAudits(params, (data) => {
      dispatch(getAudits(data.rows));
      setAudits(data.rows);
      dispatch(setPages(parseInt(data.count)));
    });
    fetchAuditCategories((data) => setAuditCategories(data));
    fetchAuditOrganization((data) => setAuditOrganizations(data));
    fetchAuditStatus((data) => setAuditStatuses(data));
    fetchAuditMeasures((data) => setAuditMeasures(data));
    fetchAuditTypes((data) => setAuditTypes(data));
  }, [page, records]);
  const items = useSelector((state) => state.audits.value.data);
  useEffect(() => {
    setAudits(items);
  }, [items]);
  const handleFilter = (e) => {
    let params = {
      from: fromDate,
      to: toDate,
      category: filterCategory,
      organization: filterOrganization,
      status: filterStatus,
      limit: records,
      page: page - 1,
    };
    filterAudits(params, (data) => {
      dispatch(getAudits(data.rows));
      setAudits(data.rows);
    });
  };
  const handleEditShow = (item) => {
    setEditAudit(item);
    setEditModalShow(true);
  };
  const handleExport = () => {
    if (audits) {
      exportReport(audits, "audit_export", "audit");
    } else {
      return;
    }
  };
  const clearFilter = (e) => {
    let params = {
      from: "",
      to: "",
      category: "",
      organization: "",
      status: "",
    };
    filterAudits(params, (data) => {
      dispatch(getAudits(data.rows));
      setAudits(data.rows);
      setFromDate("");
      setToDate("");
      setFilterCategory("");
      setFilterOrganization("");
      setFilterStatus("");
    });
  };
  const handleSingleAudit = (e, audit) => {
    navigate(`${audit.id}`, { state: { audit } }, { replace: false });
  };

  const getCategory = (id) => {
    let plate = "";
    auditCategories.forEach((category) => {
      if (category.id === id) {
        plate = category.name;
      }
    });
    return plate;
  };
  const getmeasure = (id) => {
    let plate = "";
    auditMeasures.forEach((category) => {
      if (category.id === id) {
        plate = category.name;
      }
    });
    return plate;
  };
  const gettype = (id) => {
    let plate = "";
    auditTypes.forEach((category) => {
      if (category.id === id) {
        plate = category.name;
      }
    });
    return plate;
  };
  const getorganization = (id) => {
    let plate = "";
    auditOrganizations.forEach((category) => {
      if (category.id === id) {
        plate = category.name;
      }
    });
    return plate;
  };
  const getstatus = (id) => {
    let plate = "";
    auditStatuses.forEach((category) => {
      if (category.id === id) {
        plate = category.name;
      }
    });
    return plate;
  };

  const displayCard =
    audits?.length > 0 ? (
      audits.map((audit) => (
        <div className="container card m-2">
          <div className="row">
            <div className="col-8">
              <div className="card-body p-2">
                <h5> ID : {audit.id}</h5>
                <p>Name :{audit.name} </p>
                <p>Category : {getCategory(audit.audit_category_id)}</p>
                <p>Measure : {getmeasure(audit.audit_measure_id)}</p>
                <p>Type : {gettype(audit.audit_type_id)}</p>
                <p>Description : {audit.description}</p>
              </div>
            </div>
            <div className="col-4">
              <div className="card-body p-2">
                <p>Organization :{getorganization(audit.organization_id)}</p>
                <p>
                  DueDate :
                  {new Date(audit.due_date).toLocaleString("en-US", {
                    month: "short",
                    day: "numeric",
                    year: "numeric",
                  })}
                </p>
                <p>Status : {getstatus(parseInt(audit.status))}</p>
                <Button
                  className="btn btn-secondary mx-1"
                  onClick={(e) => handleSingleAudit(e, audit)}
                >
                  <i className="fa fa-eye"></i>
                </Button>
                <Button
                  onClick={() => handleEditShow(audit)}
                  className=" btn btn-secondary"
                >
                  <i className="fa fa-pen"></i>
                </Button>
              </div>
            </div>
          </div>
        </div>
      ))
    ) : (
      <div className="card"> 0 Records</div>
    );

  const displayAudits =
    audits instanceof Array ? (
      audits.length > 0 ? (
        audits.map((audit) => (
          <tr key={audit.id} style={{}}>
            <td>{audit.id}</td>
            <td>{audit.name}</td>
            <td>{getCategory(audit.audit_category_id)}</td>
            <td>{getmeasure(audit.audit_measure_id)}</td>
            <td>{gettype(audit.audit_type_id)}</td>
            <td>{getorganization(audit.organization_id)}</td>
            <td>{audit.description}</td>
            <td>
              {new Date(audit.due_date).toLocaleString("en-US", {
                month: "short",
                day: "numeric",
                year: "numeric",
              })}
            </td>
            <td>{getstatus(parseInt(audit.status))}</td>
            <td>
              <Button
                className="btn btn-secondary mx-1"
                onClick={(e) => handleSingleAudit(e, audit)}
              >
                <i className="fa fa-eye"></i>
              </Button>
            </td>
            <td>
              <Button
                onClick={() => handleEditShow(audit)}
                className=" btn btn-secondary"
              >
                <i className="fa fa-pen"></i>
              </Button>
            </td>
          </tr>
        ))
      ) : (
        <tr className="card-body">
          <td>0 Records </td>
        </tr>
      )
    ) : null;

  return (
    <div>
      <Navbar Active="audits" />
      <div className="mt-1 container">
        <div className="" style={{}}>
          <Modal show={editModalShow}>
            <Modal.Header>
              <Modal.Title id="contained-modal-title-vcenter">
                Update Audit
              </Modal.Title>
              <Button
                variant="danger"
                type="submit"
                onClick={() => setEditModalShow(false)}
              >
                Close
              </Button>
            </Modal.Header>
            <Modal.Body>
              <EditAuditForm currentAudit={currentAudit} />
            </Modal.Body>
          </Modal>
          <ModalAudit show={modalShow} close={() => setModalShow(false)} />
          <div>
            {user.role === 1 ? (
              <Button
                onClick={() => openManagement()}
                className=" btn btn-secondary"
              >
                Manage
              </Button>
            ) : null}
            <Button
              onClick={() => setModalShow(true)}
              className=" btn btn-secondary m-1"
            >
              Add
            </Button>

            <Button
              className="btn-danger m-1"
              style={{ float: "right" }}
              onClick={handleExport}
              disabled={audits ? false : true}
            >
              Export
            </Button>
          </div>

          <div className="card-columns mb-0">
            <div
              className="card mb-0"
              style={{
                borderLeft: "none",
                borderRight: "none",
                borderBottom: "none",
              }}
            >
              <div className="card-body mb-0 ">
                <input
                  type="date"
                  id="start_date"
                  placeholder="From"
                  className="report-card"
                  value={fromDate}
                  onChange={(e) => setFromDate(e.target.value)}
                />
                <span> - </span>
                <input
                  type="date"
                  id="end_date"
                  placeholder="To"
                  className="report-card"
                  value={toDate}
                  onChange={(e) => setToDate(e.target.value)}
                />
                <select
                  aria-label="Default select example"
                  className="report-select "
                  value={filterCategory}
                  onChange={(e) => setFilterCategory(e.target.value)}
                >
                  <option value="">Select Category</option>
                  {auditCategories
                    ? auditCategories.map((category) => (
                        <option value={category.id} key={category.id}>
                          {" "}
                          {category.name}
                        </option>
                      ))
                    : null}
                </select>
                <select
                  aria-label="Default select example"
                  className="report-select"
                  value={filterOrganization}
                  onChange={(e) => setFilterOrganization(e.target.value)}
                >
                  <option value="">Select Organization</option>
                  {auditOrganizations
                    ? auditOrganizations.map((organization) => (
                        <option value={organization.id} key={organization.id}>
                          {organization.name}
                        </option>
                      ))
                    : null}
                </select>
                <select
                  aria-label="Default select example"
                  className="report-select "
                  value={filterStatus}
                  onChange={(e) => setFilterStatus(e.target.value)}
                >
                  <option value="">Select Status</option>
                  {auditStatuses
                    ? auditStatuses.map((status) => (
                        <option value={status.id} key={status.id}>
                          {status.name}
                        </option>
                      ))
                    : null}
                </select>
                <Button
                  className="btn-secondary report-button"
                  onClick={handleFilter}
                >
                  Filter
                </Button>
                <Button
                  className="btn btn-secondary report-button"
                  data-toggle="modal"
                  onClick={clearFilter}
                >
                  Clear
                </Button>
              </div>
            </div>
          </div>
          {window.innerWidth > 1200 ? (
            <div className="card  table-responsive">
              <table className="table table-hover">
                <thead className="card-header">
                  <tr className="px-4">
                    <th>ID</th>
                    <th>Name</th>
                    <th>Category</th>
                    <th>Measure</th>
                    <th>Type</th>
                    <th>Organization</th>
                    <th>Description</th>
                    <th>DueDate</th>
                    <th>Status</th>
                    <th></th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>{displayAudits}</tbody>
              </table>
            </div>
          ) : (
            displayCard
          )}
          <Paginator page="audit" />
        </div>
      </div>
    </div>
  );
};

export default Audit;
