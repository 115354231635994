import { createSlice } from "@reduxjs/toolkit";

const initialtypes = {
  data: [],
  errMsg: "",
};

export const vehicleSlice = createSlice({
  name: "vehicles",
  initialState: { value: initialtypes },
  reducers: {
    getAllVehicles: (state, action) => {
      state.value.data = action.payload;
    },
    logoutVehicles: (state) => {
      state.value = initialtypes;
    },
  },
});

export const { getAllVehicles, logoutVehicles } = vehicleSlice.actions;
export const selectVehicles = (state) => state.Vehicles;
export default vehicleSlice.reducer;
