import React, { useState, useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import jwt_decode from "jwt-decode";

import axios from "../utils/axios";
import { setCurrentUser } from "../reducers/user";

const Login = ({ message }) => {
  const dispatch = useDispatch();

  const navigate = useNavigate();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errMsg, setErrMsg] = useState("");

  const errRef = useRef();

  useEffect(() => {
    setErrMsg("");
  }, [email, password]);

  const validateEmail = (email) => {
    return email.match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
  };

  const onLogin = () => {
    if (email === "") {
      setErrMsg(" Email cannot be Empty");
    } else if (password === "") {
      setErrMsg(" Password cannot be Empty");
    } else {
      if (validateEmail(email)) {
        let url = `/users/login`;
        let data = { email, password };

        axios
          .post(url, data)
          .then((resp) => {
            const accessToken = resp.data.accessToken;
            // set token to local storage
            localStorage.setItem("jwtToken", accessToken);

            // set token to auth header
            const decoded = jwt_decode(accessToken);
            let user = {
              name: decoded.name,
              job: decoded.job_title,
              role: decoded.role_id,
              no: decoded.id,
            };
            // set current user
            dispatch(setCurrentUser(user));

            navigate("/home");
          })
          .catch((err) => {
            if (err.response.data !== undefined) {
              const error = String(err.response.data.error.error);
              setErrMsg(error);
            } else {
              setErrMsg("A network error occured");
            }
          });
      } else {
        setErrMsg("Email  Is not Valid");
      }
    }
  };
  return (
    <div className="loginContainer d-flex align-items-center justify-content-center mt-5 ">
      <div className="col-md-3 shadow p-4">
        <div
          ref={errRef}
          className={errMsg ? "errmsg" : "offscreen"}
          aria-live="assertive"
        >
          {errMsg}
        </div>
        <div
          ref={errRef}
          className={message ? "errmsg" : "offscreen"}
          aria-live="assertive"
        >
          {message}
        </div>
        <div className="mb-3">
          <h4 className="d-flex justify-content-center my-3">Welcome</h4>
          <label htmlFor="exampleInputEmail1" className="form-label">
            Email
          </label>
          <input
            type="text"
            className="form-control"
            aria-describedby="emailHelp"
            placeholder="example@example.com"
            onChange={(e) => setEmail(e.target.value)}
          />
        </div>
        <div className="mb-3">
          <label htmlFor="exampleInputPassword1" className="form-label">
            Password
          </label>
          <input
            type="password"
            className="form-control"
            placeholder="Password"
            onChange={(e) => setPassword(e.target.value)}
          />
        </div>
        <div className="d-grid gap-2 mt-7">
          <button type="submit" className="btn btn-warning" onClick={onLogin}>
            Login
          </button>
          <div>
            <a href="/reset">Forgot Password?</a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
