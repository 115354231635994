import React, { useState } from "react";
import { useEffect } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import { fetchAuditStatus } from "../audit/audit.service";
import { useDispatch, useSelector } from "react-redux";
import { getAuditStatuses, setAuditStatuses } from "../../reducers/manageAudit";
import { getActions } from "../../reducers/auditActions";
import axios from "../../utils/axios";
import { fetchAuditActions } from "./action.service";
const AddAction = (props) => {
  const [errMsg, setErrMsg] = useState("");
  const [confMsg, setConfMsg] = useState("");
  const [newAction, setNewAction] = useState({
    actionName: "",
    actionDescription: "",
    actionRaisedBy: "",
    actionDateRaised: "",
    actionAssignedTo: "",
    actionDueDate: "",
    actionStatus: "",
    actionComments: "",
  });
  const id = props.id;
  const dispatch = useDispatch();

  const actionStatuses = useSelector(getAuditStatuses);
  const handleSubmit = (e) => {
    e.preventDefault();
    if (!newAction.actionName.trim()) {
      setErrMsg("Action name is required");
    } else if (!newAction.actionDescription.trim()) {
      setErrMsg("Description is required");
    } else if (!newAction.actionRaisedBy.trim()) {
      setErrMsg("Raised by is required");
    } else if (!newAction.actionDateRaised) {
      setErrMsg("Date raised is required");
    } else if (!newAction.actionAssignedTo) {
      setErrMsg("Assigned to is required");
    } else if (!newAction.actionDueDate) {
      setErrMsg("Due date is required");
    } else if (!newAction.actionStatus) {
      setErrMsg("Status is required is required");
    } else {
      const url = `actions/${id}`;
      axios
        .post(
          url,

          {
            name: newAction.actionName,
            description: newAction.actionDescription,
            raised_by: newAction.actionRaisedBy,
            date_raised: newAction.actionDateRaised,
            assigned_to: newAction.actionAssignedTo,
            due_date: newAction.actionDueDate,
            status_id: newAction.actionStatus,
            comments: newAction.actionComments,
          }
        )

        .then((res) => {
          setConfMsg(res.data.message);
          const updateAction = {
            actionName: "",
            actionDescription: "",
            actionRaisedBy: "",
            actionDateRaised: "",
            actionAssignedTo: "",
            actionDueDate: "",
            actionStatus: "",
            actionComments: "",
          };
          setNewAction({
            ...newAction,
            ...updateAction,
          });
          setTimeout(() => {
            setConfMsg("");
            if (e.target.id === "submitClose") {
              props.close();
            }
          }, 2000);

          fetchAuditActions(id, (data) => dispatch(getActions(data)));
        })
        .catch((err) => {
          if (err.response.data !== undefined) {
            const error = String(err.response.data.error);
            setErrMsg(error);
          } else {
            setErrMsg("A network error occured");
          }
        });
    }
  };

  const handleActionChange = (e) => {
    const actionUpdate = {};
    let actionAttribute = e.target.id;
    actionUpdate[actionAttribute] = e.target.value;
    setNewAction({ ...newAction, ...actionUpdate });
    setErrMsg("");
  };
  const handleClose = () => {
    props.close();
    setErrMsg("");
    setNewAction({
      actionName: "",
      actionDescription: "",
      actionRaisedBy: "",
      actionDateRaised: "",
      actionAssignedTo: "",
      actionDueDate: "",
      actionStatus: "",
      actionComments: "",
    });
  };
  return (
    <Modal show={props.show}>
      <Modal.Header>
        <Modal.Title id="contained-modal-title-vcenter">Add Action</Modal.Title>
        <Button variant="danger" type="submit" onClick={handleClose}>
          Close
        </Button>
      </Modal.Header>
      <Modal.Body>
        <div className={errMsg ? "errmsg" : "offscreen"}>{errMsg}</div>
        <div className={confMsg ? "confmsg" : "offscreen"}>{confMsg}</div>

        <form>
          <div>
            <label htmlFor="actionName">Name</label>
            <input
              type="text"
              className="form-control"
              id="actionName"
              value={newAction.actionName}
              onChange={handleActionChange}
            />
          </div>
          <div>
            <label htmlFor="actionName">Description</label>
            <textarea
              className="form-control"
              id="actionDescription"
              value={newAction.actionDescription}
              onChange={(e) => handleActionChange(e)}
            ></textarea>
          </div>
          <div>
            <label htmlFor="actionName">Raised by</label>
            <input
              type="text"
              className="form-control"
              id="actionRaisedBy"
              value={newAction.actionRaisedBy}
              onChange={handleActionChange}
            />
          </div>
          <div>
            <label htmlFor="actionName">Date Raised</label>
            <input
              type="date"
              className="form-control"
              id="actionDateRaised"
              value={newAction.actionDateRaised}
              onChange={handleActionChange}
            />
          </div>
          <div>
            <label htmlFor="actionName">Responsible</label>
            <input
              type="text"
              className="form-control"
              id="actionAssignedTo"
              value={newAction.actionAssignedTo}
              onChange={handleActionChange}
            />
          </div>
          <div>
            <label htmlFor="actionName">Due Date</label>
            <input
              type="date"
              className="form-control"
              id="actionDueDate"
              value={newAction.actionDueDate}
              onChange={handleActionChange}
            />
          </div>
          <div>
            <label htmlFor="actionName">Status</label>
            <Form.Select
              id="actionStatus"
              value={newAction.actionStatus}
              onChange={handleActionChange}
            >
              <option>Select status</option>
              {Array.isArray(actionStatuses) &&
                actionStatuses?.map((status) => {
                  return (
                    <option key={status.id} value={status.id}>
                      {status.name}
                    </option>
                  );
                })}
            </Form.Select>
          </div>
          <div>
            <label htmlFor="actionName">Comments</label>
            <textarea
              className="form-control"
              id="actionComments"
              value={newAction.actionComments}
              onChange={handleActionChange}
            ></textarea>
          </div>

          <div className="">
            <button
              className="btn btn-warning my-3"
              id="submitNew"
              onClick={(e) => {
                // setClose(false);
                handleSubmit(e);
              }}
            >
              Save and New
            </button>
            <button
              className="btn btn-warning my-3 float-end"
              id="submitClose"
              onClick={(e) => {
                // setClose(true);
                handleSubmit(e);
              }}
            >
              Save and Close
            </button>
          </div>
        </form>
      </Modal.Body>
    </Modal>
  );
};

export default AddAction;
