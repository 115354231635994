import { createSlice } from "@reduxjs/toolkit";

const initialtypes = {
  data: [],
  errMsg: "",
};

export const incidentTypeSlice = createSlice({
  name: "incidentTypes",
  initialState: { value: initialtypes },
  reducers: {
    getTypes: (state, action) => {
      state.value.data = action.payload;
    },
    logoutTypes: (state) => {
      state.value = initialtypes;
    },
  },
});

export const { getTypes, logoutTypes } = incidentTypeSlice.actions;
export const selectIncidentTypes = (state) => state.incidentTypes;
export default incidentTypeSlice.reducer;
