import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { Button } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import { getIncidents } from "../../reducers/incidents";
import { exportReport } from "./export.service";

import { Form } from "react-bootstrap";

import {
  fetchDrivers,
  fetchVehicles,
  fetchViolations,
  filterIncidents,
} from "./report.service";
const IncidentReport = () => {
  const incidentItems = useSelector((state) => state.incidents.value);
  let incidents = incidentItems.data.rows;
  const [drivers, setDrivers] = useState([]);
  const [vehicles, setVehicles] = useState([]);
  const [violations, setViolations] = useState([]);
  const dispatch = useDispatch();
  useEffect(() => {
    fetchDrivers((data) => setDrivers(data.rows));
    fetchViolations((data) => setViolations(data));
    fetchVehicles((data) => setVehicles(data));
  }, []);

  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [filterDriver, setFilterDriver] = useState("");
  const [filterVehicle, setFilterVehicle] = useState("");
  const [filterViolation, setFilterViolation] = useState("");

  const handleFilter = (e) => {
    let params = {
      from: fromDate,
      to: toDate,
      vehicle: filterVehicle,
      driver: filterDriver,
      violation: filterViolation,
    };
    filterIncidents(params, (data) => dispatch(getIncidents(data)));
  };
  const handleExport = () => {
    if (incidents) {
      exportReport(incidents, "incident_export", "incident");
    } else {
      return;
    }
  };
  return (
    <div className="container car mt-5">
      <form className="my-3 d-flex justify-content-between align-items-center">
        <input
          type="date"
          id="incident_start_date"
          placeholder="From"
          className="form-control mx-1"
          onChange={(e) => setFromDate(e.target.value)}
        />
        <span>-</span>
        <input
          type="date"
          id="incident_end_date"
          placeholder="To"
          className="form-control mx-1"
          onChange={(e) => setToDate(e.target.value)}
        />
        <Form.Select
          aria-label="Default select example"
          className="form-control mx-1"
          onChange={(e) => setFilterDriver(e.target.value)}
        >
          <option value="">Select Driver</option>
          {drivers
            ? drivers.map((driver) => (
                <option value={driver.id} key={driver.id}>
                  {driver.name}
                </option>
              ))
            : null}
        </Form.Select>
        <Form.Select
          aria-label="Default select example"
          className="form-control mx-1"
          onChange={(e) => setFilterVehicle(e.target.value)}
        >
          <option value="">Select Vehicle</option>
          {vehicles
            ? vehicles.map((vehicle) => (
                <option key={vehicle.id} value={vehicle.id}>
                  {vehicle.license_plate_no}
                </option>
              ))
            : null}
        </Form.Select>
        <Form.Select
          aria-label="Default select example"
          className="form-control mx-1"
          onChange={(e) => setFilterViolation(e.target.value)}
        >
          <option value="">Select Violation</option>
          {violations
            ? violations.map((violation) => (
                <option key={violation.id} value={violation.id}>
                  {violation.name}
                </option>
              ))
            : null}
        </Form.Select>

        <Button className="btn-secondary mx-1" onClick={(e) => handleFilter(e)}>
          Filter
        </Button>
        <Button
          className="btn-warning mx-1"
          onClick={handleExport}
          disabled={incidents ? false : true}
        >
          Export
        </Button>
      </form>
      <div className="table-responsive card">
        <table className="table table-borderless">
          <thead className="card-header">
            <tr>
              <th>Id</th>
              <th>Driver</th>
              <th>Vehicle</th>
              <th>Incident</th>
              <th>Description</th>
              <th>Violation</th>
              <th>Incident Date</th>
              <th>Reported by</th>
              <th>Location</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {incidents ? (
              incidents?.map((incident) => (
                <tr key={incident.id} style={{}}>
                  <td>{incident.id}</td>
                  <td>{incident.driver}</td>
                  <td>{incident.vehicle}</td>
                  <td>{incident.incident_type}</td>
                  <td>{incident.description}</td>
                  <td>{incident.violation}</td>
                  <td>
                    {new Date(incident.incident_date).toLocaleString("en-US", {
                      month: "short",
                      day: "numeric",
                      year: "numeric",
                    })}
                  </td>
                  <td>{incident.user}</td>
                  <td>{incident.location}</td>
                  <td>{incident.action}</td>
                </tr>
              ))
            ) : (
              <tr>
                <td>0 records</td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default IncidentReport;
