import { createSlice } from "@reduxjs/toolkit";

const initialpaginated = {
  data: [],
  errMsg: "",
};

export const driverPaginatedSlice = createSlice({
  name: "driversPaginated",
  initialState: { value: initialpaginated },
  reducers: {
    logoutPaginated: (state) => {
      state.value = initialpaginated;
    },
    getPaginated: (state, action) => {
      state.value.data = action.payload;
    },
  },
});

export const { logoutPaginated, getPaginated } = driverPaginatedSlice.actions;
export const selectdrivers = (state) => state.drivers;
export default driverPaginatedSlice.reducer;
