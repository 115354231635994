import React, { useState } from "react";
import { Modal, Button } from "react-bootstrap";
import {
  addCategory,
  addOrganization,
  addMeasure,
  addStatus,
  addType,
  fetchAuditCategories,
  fetchAuditOrganization,
  fetchAuditMeasures,
  fetchAuditTypes,
  fetchAuditStatus,
} from "./audit.service";
import {
  updateAuditMeasures,
  updateAuditCategories,
  updateAuditOrganizations,
  updateAuditStatuses,
  updateAuditTypes,
  setAuditCategories,
  setAuditOrganizations,
  setAuditStatuses,
  setAuditMeasures,
  setAuditTypes,
} from "../../reducers/manageAudit";
import { useDispatch } from "react-redux";
import { useEffect } from "react";

const ManageModal = (props) => {
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [confMsg, setConfMsg] = useState("");
  const [errMsg, setErrMsg] = useState("");
  const dispatch = useDispatch();

  useEffect(() => {
    setErrMsg("");
    setConfMsg("");
    if (props.item?.name) {
      setName(props.item?.name);
      setDescription(props?.item?.description);
    } else {
      setErrMsg("");
      setName("");
      setDescription("");
    }
  }, [props.item]);

  const handleSubmit = (e, attribute) => {
    e.preventDefault();
    if (!name.trim()) {
      setErrMsg("Name is required");
    } else if (!description.trim()) {
      setErrMsg("Description is required");
    } else {
      switch (attribute) {
        case "category":
          addCategory({ name, description }, props, (res, err) => {
            if (res) {
              if (props.action === "add") {
                setConfMsg("Category added successfully");
                dispatch(updateAuditCategories(res?.data));
              } else {
                setConfMsg("Category Updated successfully");
                fetchAuditCategories((data) =>
                  dispatch(setAuditCategories(data))
                );
              }
            } else if (err) {
              setErrMsg(err.response.data.error);
            }
          });
          fetchAuditCategories((payload) =>
            dispatch(setAuditCategories(payload))
          );
          break;

        case "organization":
          addOrganization({ name, description }, props, (res, err) => {
            if (res) {
              if (props.action === "add") {
                setConfMsg("Organization added successfully");
                dispatch(updateAuditOrganizations(res?.data));
              } else {
                fetchAuditOrganization((data) => {
                  setConfMsg("Organization Updated successfully");

                  dispatch(setAuditOrganizations(data));
                });
              }
            } else if (err) {
              setErrMsg(err.response.data.error);
            }
          });

          break;

        case "measure":
          addMeasure({ name, description }, props, (res, err) => {
            if (res) {
              if (props.action === "add") {
                setConfMsg("Measure added successfully");

                dispatch(updateAuditMeasures(res?.data));
              } else {
                fetchAuditMeasures((data) => {
                  setConfMsg("Measure Updated successfully");

                  dispatch(setAuditMeasures(data));
                });
              }
            } else if (err) {
              setErrMsg(err.response.data.error);
            }
          });
          break;

        case "type":
          addType({ name, description }, props, (res, err) => {
            if (res) {
              if (props.action === "add") {
                setConfMsg("Type added successfully");

                dispatch(updateAuditTypes(res?.data));
              } else {
                fetchAuditTypes((data) => {
                  setConfMsg("Type Updated successfully");

                  dispatch(setAuditTypes(data));
                });
              }
            } else if (err) {
              setErrMsg(err.response.data.error);
            }
          });
          break;

        case "status":
          addStatus({ name, description }, props, (res, err) => {
            if (res) {
              if (props.action === "add") {
                setConfMsg("Status Added successfully");

                dispatch(updateAuditStatuses(res?.data));
              } else {
                fetchAuditStatus((data) => {
                  setConfMsg("Status Updated successfully");
                  dispatch(setAuditStatuses(data));
                });
              }
            } else if (err) {
              setErrMsg(err.response.data.error);
            }
          });
          break;
        default:
          break;
      }
    }
  };
  return (
    <Modal show={props.show}>
      <Modal.Header>
        <Modal.Title id="contained-modal-title-vcenter">
          {props.title}
        </Modal.Title>
        <Button variant="danger" type="submit" onClick={props.close}>
          Close
        </Button>
      </Modal.Header>
      <Modal.Body>
        <div className={errMsg ? "errmsg" : "offscreen"}>{errMsg}</div>
        <div className={confMsg ? "confmsg" : "offscreen"}>{confMsg}</div>

        <form onSubmit={(e) => handleSubmit(e, props.attribute)}>
          <input
            type="text"
            placeholder={`Audit ${props.attribute}`}
            value={name}
            onChange={(e) => {
              setName(e.target.value);
              setErrMsg("");
              setConfMsg("");
            }}
            className="form-control"
          />
          <input
            type="text"
            placeholder="Description"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            className="form-control mt-2"
          />

          <input
            type="submit"
            value={props.action}
            className="btn btn-warning my-2 form-control"
          />
        </form>
      </Modal.Body>
    </Modal>
  );
};

export default ManageModal;
