import React, { useState, useEffect } from "react";
import { Form } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";

import axios from "../../utils/axios";
import "../../App.css";
import { getIncidents } from "../../reducers/incidents";

const EditForm = ({ Currentincident }) => {
  const dispatch = useDispatch();
  //allincidents
  const incidents = useSelector((state) => state.incidents.value.data);

  const [errMsg, setErrMsg] = useState("");
  const [confMsg, setconfMsg] = useState("");
  const [incident, setIncident] = useState(Currentincident);

  const [allincidents] = useState(incidents);
  useEffect(() => {
    setErrMsg("");
    setconfMsg("");
  }, [Currentincident]);

  const types = useSelector((state) => state.incidentTypes.value);
  const typesincidents = types.data;

  //drivers
  const driverData = useSelector((state) => state.drivers.value);
  const drivers = driverData.data;

  //violations
  const viols = useSelector((state) => state.violations.value);
  const violations = viols.data;
  // category
  const viol_cs = useSelector((state) => state.violationCategories.value);
  const violation_cs = viol_cs.data;
  //vehicles
  const Vehicless = useSelector((state) => state.vehicles.value);
  const vehicles = Vehicless.data;
  //value inputs
  const onInputChange = (e) => {
    setIncident({ ...incident, [e.target.name]: e.target.value });
  };
  const getVehicle = (id) => {
    let plate = "";
    vehicles.forEach((vehicle) => {
      if (vehicle.id === id) {
        plate = vehicle.license_plate_no;
      }
    });
    return plate;
  };
  const getDriver = (id) => {
    let name = "";
    drivers.forEach((driver) => {
      if (driver.id === id) {
        name = driver.name;
      }
    });
    return name;
  };

  const getIncidentType = (id) => {
    let type = "";
    typesincidents.forEach((typesincident) => {
      if (typesincident.id === id) {
        type = typesincident.name;
      }
    });
    return type;
  };
  const getViolation = (id) => {
    if (!id) {
      return "N/A";
    } else {
      let name = "";
      violations.forEach((violation) => {
        if (violation.id === id) {
          name = violation.name;
        }
      });
      return name;
    }
  };
  const getViolationCategory = (id) => {
    if (!id) {
      return "N/A";
    } else {
      let name = "";
      violation_cs.forEach((violation) => {
        if (violation.id === id) {
          name = violation.name;
        }
      });
      return name;
    }
  };
  //save edits
  const handleEdit = (e) => {
    e.preventDefault();
    if (incident.driver_id === "") {
      setErrMsg(" Driver cannot be Empty");
    } else if (incident.vehicle_id === "") {
      setErrMsg(" Vehicle cannot be Empty");
    } else if (incident.incident_type_id === "") {
      setErrMsg(" Incident cannot be Empty");
    } else if (incident.location.trim() === "") {
      setErrMsg(" Location cannot be Empty");
    } else if (incident.violation_id === "") {
      setErrMsg(" Violation cannot be Empty");
    } else if (incident.description.trim() === "") {
      setErrMsg(" Description cannot be Empty");
    } else if (incident.description.replaceAll("  ", "").length > 255) {
      setErrMsg(" Description is too long");
    } else if (incident.action.trim() === "") {
      setErrMsg(" Action cannot be Empty");
    } else if (incident.incident_date === "") {
      setErrMsg(" Date cannot be Empty");
    } else {
      let url = `/incidents/${Currentincident.id}`;
      axios
        .patch(url, incident)
        .then((resp) => {
          let allnew = allincidents.map((allivident) =>
            allivident.id === incident.id ? (allivident = incident) : allivident
          );
          dispatch(getIncidents(allnew));
          setconfMsg(resp.data.message);
        })
        .catch((err) => {
          if (err.response.data.error) {
            const error = err.response.data.error;
            setErrMsg(error);
          } else {
            setErrMsg(err.message);
          }
          setconfMsg("");
        });
    }
  };

  return (
    <div>
      <div className={confMsg ? "confmsg" : "offscreen"} aria-live="assertive">
        {confMsg}
      </div>
      <div className={errMsg ? "errmsg" : "offscreen"} aria-live="assertive">
        {errMsg}
      </div>

      <Form>
        <Form.Group>
          <Form.Label>Driver</Form.Label>
          <Form.Control
            type="text"
            value={getDriver(incident?.driver_id)}
            disabled
            required
          ></Form.Control>
        </Form.Group>
        <Form.Group>
          <Form.Label>Vehicle</Form.Label>
          <Form.Control
            type="text"
            placeholder="Vehicle Plate"
            value={getVehicle(incident.vehicle_id)}
            required
            disabled
          ></Form.Control>
        </Form.Group>
        <Form.Group>
          <Form.Label>Event</Form.Label>
          <Form.Control
            type="text"
            value={getIncidentType(incident.incident_type_id)}
            required
            disabled
          ></Form.Control>
        </Form.Group>
        <Form.Group>
          <Form.Label>Description</Form.Label>
          <Form.Control
            as="textarea"
            rows={3}
            type="text"
            placeholder="Description"
            value={incident.description}
            name="description"
            onChange={onInputChange}
            required
          />
        </Form.Group>
        <Form.Group>
          <Form.Label>Action</Form.Label>
          <Form.Control
            type="text"
            placeholder="Action"
            value={incident.action}
            name="action"
            onChange={onInputChange}
            required
          />
        </Form.Group>
        <Form.Group>
          <Form.Label>Location</Form.Label>
          <Form.Control
            type="text"
            placeholder="Where"
            value={incident.location}
            name="location"
            onChange={onInputChange}
            required
          />
        </Form.Group>
        <Form.Group>
          <Form.Label>RootCause</Form.Label>
          <Form.Control
            type="text"
            value={getViolation(incident.violation_id)}
            required
            disabled
          ></Form.Control>
        </Form.Group>
        <Form.Group>
          <Form.Label>RootCause Category</Form.Label>
          <Form.Control
            type="text"
            value={getViolationCategory(incident.violation_category_id)}
            required
            disabled
          ></Form.Control>
        </Form.Group>
        <Form.Group>
          <Form.Label>Event Date</Form.Label>
          <Form.Control
            type="text"
            value={moment(incident.incident_date).format(
              "MMMM Do YYYY, h:mm a"
            )}
            className="form-control"
            disabled
          />
        </Form.Group>
        <div className="d-grid gap-6 mt-7 pt-3">
          <button
            type="submit"
            className="btn btn-warning"
            onClick={handleEdit}
          >
            Save
          </button>
        </div>
      </Form>
    </div>
  );
};

export default EditForm;
