import { createSlice } from "@reduxjs/toolkit";

const initialcategories = {
  data: [],
  errMsg: "",
};

export const incidentCategoriesSlice = createSlice({
  name: "IncidentCategories",
  initialState: { value: initialcategories },
  reducers: {
    getCategories: (state, action) => {
      state.value.data = action.payload;
    },
    logoutCategories: (state) => {
      state.value = initialcategories;
    },
  },
});
export const { getCategories, logoutCategories } =
  incidentCategoriesSlice.actions;
export const selectIncidentCategories = (state) => state.IncidentCategories;
export default incidentCategoriesSlice.reducer;
