import { createSlice } from "@reduxjs/toolkit";

const initialtypes = {
  data: [],
  errMsg: "",
};

export const violationSlice = createSlice({
  name: "violations",
  initialState: { value: initialtypes },
  reducers: {
    getAll: (state, action) => {
      state.value.data = action.payload;
    },
    logoutViolations: (state) => {
      state.value = initialtypes;
    },
  },
});

export const { getAll, logoutViolations } = violationSlice.actions;
export const selectviolations = (state) => state.violations;
export default violationSlice.reducer;
