import { createSlice } from "@reduxjs/toolkit"; // help create logic
//contains actual name of state and actions to be done on user state

const initialStateValue = {
  email: "",
  errMsg: "",
  currentUser: "",
};
export const userSlice = createSlice({
  name: "user",
  initialState: { value: initialStateValue },
  reducers: {
    //actions by the user
    login: (state, action) => {
      state.value = action.payload;
    },
    logout: (state) => {
      state.value = initialStateValue;
    },
    setCurrentUser: (state, decoded) => {
      state.value.currentUser = decoded;
      state.value.currentUser.payload.LoggedIn = true;
      // set user to local storage
      localStorage.setItem(
        "user",
        JSON.stringify(state.value.currentUser.payload)
      );
    },
  },
});
export const { logout, login, setCurrentUser } = userSlice.actions;
export const selectUser = (state) => state.user;
export default userSlice.reducer;
