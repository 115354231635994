import React from "react";
import { useLocation } from "react-router-dom";
import Navbar from "../Navbar";
import { Button } from "react-bootstrap";
import Actions from "../actions/Actions";
const SingleAudit = () => {
  const location = useLocation();
  let audit;

  audit = location.state?.audit;

  const formatDate = (raw_date) => {
    return new Date(raw_date).toLocaleString("en-US", {
      month: "short",
      day: "numeric",
      year: "numeric",
    });
  };
  return (
    <div>
      <Navbar Active="audits" />
      <div className="container mt-5">
        <div className="row">
          <div className="col-8">
            <div className="card p-2">
              <p>Audit id: {audit.id}</p>
              <p>
                Audit Name: <span className="">{audit.name}</span>
              </p>
              <p>
                Audit Description: <span className=""></span>
                {audit.description}
              </p>
              <p>
                Audit Type: <span className=""></span>
                {audit.audit_type_name}
              </p>
              <p>
                Audit Category: <span className=""></span>
                {audit.audit_category_name}
              </p>
              <p>
                Audit Organization: <span className=""></span>
                {audit.organization_name}
              </p>
              <p>
                Audit Measure: <span className=""></span>
                {audit.audit_measure_name}
              </p>
            </div>
          </div>
          <div className="col-4">
            <div className="card p-2">
              <p>
                <span className="badge bg-dark">{audit.status_name}</span>
              </p>
              <p>Raised By: {audit.raised_by}</p>
              <p>Raised On: {formatDate(audit.date_raised)}</p>
              <p>Responsible: {audit.accountable}</p>
              <p>Due Date: {formatDate(audit.due_date)}</p>
              <p>Created On:{formatDate(audit.created_at)}</p>
            </div>
          </div>
        </div>
        <Actions id={audit.id} />
      </div>
    </div>
  );
};

export default SingleAudit;
