import { createSlice } from "@reduxjs/toolkit";

const initialStateValue={
    data:"",
    errMsg:"",
};

export const actionSlice=createSlice({

    name:"actions",
    initialState:{value:initialStateValue},
    reducers:{
        getActions:(state,action)=>{
            state.value.data=action.payload;
        },
        logoutActions:(state)=>{
            state.value=initialStateValue;
        }
    }
});

export const {getActions,logoutActions}=actionSlice.actions;
export const selectActions=(state=>state.actions.value.data)
export default actionSlice.reducer