import React, { useState } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { getAuditStatuses } from "../../reducers/manageAudit";
import { getActions } from "../../reducers/auditActions";
import axios from "../../utils/axios";
import { fetchAuditActions } from "./action.service";
const EditAction = (props) => {
  const [errMsg, setErrMsg] = useState("");
  const [confMsg, setConfMsg] = useState("");
  const action = props.action;
  const audit_id = props.audit_id;
  const [targetAction, setTargetAction] = useState({
    actionName: action.name,
    actionDescription: action.description,
    actionRaisedBy: action.raised_by,
    actionDateRaised: action.date_raised,
    actionAssignedTo: action.assigned_to,
    actionDueDate: action.due_date,
    actionStatus: action.status_id,
    actionComments: action.comments,
  });
  const id = action.id;
  const dispatch = useDispatch();
  const actionStatuses = useSelector(getAuditStatuses);
  const handleSubmit = (e) => {
    e.preventDefault();
    if (!targetAction.actionName.trim()) {
      setErrMsg("Action name is required");
    } else if (!targetAction.actionDescription.trim()) {
      setErrMsg("Description is required");
    } else if (!targetAction.actionRaisedBy.trim()) {
      setErrMsg("Raised by is required");
    } else if (!targetAction.actionDateRaised) {
      setErrMsg("Date raised is required");
    } else if (!targetAction.actionAssignedTo) {
      setErrMsg("Assigned to is required");
    } else if (!targetAction.actionDueDate) {
      setErrMsg("Due date is required");
    } else if (!targetAction.actionStatus) {
      setErrMsg("Status is required is required");
    } else {
      const url = `actions/${id}`;
      axios
        .patch(
          url,

          {
            name: targetAction.actionName,
            description: targetAction.actionDescription,
            raised_by: targetAction.actionRaisedBy,
            date_raised: targetAction.actionDateRaised,
            assigned_to: targetAction.actionAssignedTo,
            due_date: targetAction.actionDueDate,
            status_id: targetAction.actionStatus,
            comments: targetAction.actionComments,
            audit_id: audit_id,
          }
        )

        .then((res) => {
          setErrMsg("");
          setConfMsg(res.data.message);
          fetchAuditActions(audit_id, (data) => dispatch(getActions(data)));
        })
        .catch((err) => {
          setConfMsg("");
          if (err.response.data !== undefined) {
            const error = String(err.response.data.error);
            setErrMsg(error);
          } else {
            setErrMsg("A network error occured");
          }
        });
    }
  };

  const handleActionChange = (e) => {
    const actionUpdate = {};
    let actionAttribute = e.target.id;
    actionUpdate[actionAttribute] = e.target.value;
    setTargetAction({ ...targetAction, ...actionUpdate });
    setErrMsg("");
  };

  return (
    <Modal show={props.show}>
      <Modal.Header>
        <Modal.Title id="contained-modal-title-vcenter">
          Edit Action
        </Modal.Title>
        <Button variant="danger" type="submit" onClick={props.close}>
          Close
        </Button>
      </Modal.Header>
      <Modal.Body>
        <div className={errMsg ? "errmsg" : "offscreen"}>{errMsg}</div>
        <div className={confMsg ? "confmsg" : "offscreen"}>{confMsg}</div>

        <form onSubmit={(e) => handleSubmit(e, props.attribute)}>
          <div>
            <label htmlFor="actionName">Name</label>
            <input
              type="text"
              className="form-control"
              id="actionName"
              value={targetAction.actionName}
              onChange={handleActionChange}
              disabled
            />
          </div>
          <div>
            <label htmlFor="actionName">Description</label>
            <textarea
              className="form-control"
              id="actionDescription"
              value={targetAction.actionDescription}
              onChange={(e) => handleActionChange(e)}
            ></textarea>
          </div>
          <div>
            <label htmlFor="actionName">Raised by</label>
            <input
              type="text"
              className="form-control"
              id="actionRaisedBy"
              value={targetAction.actionRaisedBy}
              onChange={handleActionChange}
              disabled
            />
          </div>
          <div>
            <label htmlFor="actionName">Date Raised</label>
            <input
              type="date"
              className="form-control"
              id="actionDateRaised"
              value={targetAction.actionDateRaised}
              onChange={handleActionChange}
              disabled
            />
          </div>
          <div>
            <label htmlFor="actionName">Responsible</label>
            <input
              type="text"
              className="form-control"
              id="actionAssignedTo"
              value={targetAction.actionAssignedTo}
              onChange={handleActionChange}
            />
          </div>
          <div>
            <label htmlFor="actionName">Due Date</label>
            <input
              type="date"
              className="form-control"
              id="actionDueDate"
              value={targetAction.actionDueDate}
              onChange={handleActionChange}
            />
          </div>
          <div>
            <label htmlFor="actionName">Status</label>
            <Form.Select
              id="actionStatus"
              value={targetAction.actionStatus}
              onChange={handleActionChange}
            >
              <option>Select type</option>
              {Array.isArray(actionStatuses) &&
                actionStatuses?.map((status) => {
                  return (
                    <option key={status.id} value={status.id}>
                      {status.name}
                    </option>
                  );
                })}
            </Form.Select>
          </div>
          <div>
            <label htmlFor="actionName">Comments</label>
            <textarea
              className="form-control"
              id="actionComments"
              value={targetAction.actionComments}
              onChange={handleActionChange}
            ></textarea>
          </div>
          <input
            type="submit"
            value="Edit"
            className="btn btn-warning my-2 form-control"
          />
        </form>
      </Modal.Body>
    </Modal>
  );
};

export default EditAction;
