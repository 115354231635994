import { createSlice } from "@reduxjs/toolkit";

const initialtypes = {
  data: [],
  errMsg: "",
};

export const driverSlice = createSlice({
  name: "drivers",
  initialState: { value: initialtypes },
  reducers: {
    getAllDrivers: (state, action) => {
      state.value.data = action.payload;
    },
    logoutDrivers: (state) => {
      state.value = initialtypes;
    },
  },
});

export const { getAllDrivers, logoutDrivers } = driverSlice.actions;
export const selectdrivers = (state) => state.drivers;
export default driverSlice.reducer;
