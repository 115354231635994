import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import { logout } from "../../reducers/user";
import { logoutIncidents } from "../../reducers/incidents";
import { logoutTypes } from "../../reducers/incidentTypes";
import { logoutViolations } from "../../reducers/violations";
import { logoutDrivers } from "../../reducers/drivers";
import { logoutVehicles } from "../../reducers/vehicles";
import { logoutPaginated } from "../../reducers/driverPaginated";
import { logoutAudits } from "../../reducers/audit";
import { logoutPersonnels } from "../../reducers/personnel";
import { logoutCategories } from "../../reducers/incidentCategories";
import { logoutRoles } from "../../reducers/roles";
import axios from "../../utils/axios";
import moment from "moment";

const UserAccount = ({ id }) => {
  const dispatch = useDispatch();

  const navigate = useNavigate();
  const [errMsg, setErrMsg] = useState("");
  const [currUser, setCurrUser] = useState("");
  const url = `/users/${id}`;
  useEffect(() => {
    axios
      .get(url)
      .then((response) => {
        setErrMsg("");
        setCurrUser(response.data);
      })
      .catch((err) => {
        let error = String(err.response.data.error);
        setErrMsg(error);
      });
  }, [url]);

  const onLogout = () => {
    //clear local storage
    localStorage.clear();

    //Clear State
    dispatch(logoutDrivers());
    dispatch(logout());
    dispatch(logoutVehicles());
    dispatch(logoutViolations());
    dispatch(logoutIncidents());
    dispatch(logoutTypes());
    dispatch(logoutPaginated());
    dispatch(logoutAudits());
    dispatch(logoutCategories());
    dispatch(logoutPersonnels());
    dispatch(logoutRoles());
    //redirect to login page
    navigate("/");
  };
  return (
    <div>
      <div className={errMsg ? "errmsg" : "offscreen"} aria-live="assertive">
        {errMsg}
      </div>
      <div className="container-fluid">
        <h5 className="card-body">Name : {currUser.name}</h5>
        <h5 className="card-body">Job Title: {currUser.job_title}</h5>
        <h5 className="card-body"> Phone Number : +{currUser.phone}</h5>
        <h5 className="card-body">Email:{currUser.email}</h5>
        <h5 className="card-body">
          Status : {currUser.is_active === true ? "Active" : "Inactive"}
        </h5>
        <h5 className="card-body">
          Role : {currUser.role_id === 1 ? "Admin" : "User"}
        </h5>
        <h5 className="card-body">
          Joined: {moment(currUser.created_at).format("MMM DD YYYY, h:mm:ss a")}
        </h5>
      </div>
      <button
        type="submit"
        style={{ borderRadius: "25px" }}
        className="form-control my-3 float-end btn btn-secondary"
        onClick={onLogout}
      >
        <h5>Logout Account</h5>
      </button>
    </div>
  );
};

export default UserAccount;
