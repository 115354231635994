import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { getPaginated } from "../../reducers/driverPaginated";
import axios from "../../utils/axios";
import { useDispatch } from "react-redux";
import Paginator from "../pagination/Paginator";
import { selectPage, selectLimit, setPages } from "../../reducers/pagination";

import { useNavigate } from "react-router-dom";

const Table = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  let page = useSelector(selectPage);
  let limit = useSelector(selectLimit);
  const paginatedurl = `/drivers/all?page=${
    page - 1
  }&limit=${limit}&order=core_driver_id&orderMethod=DESC`;
  useEffect(() => {
    axios
      .get(paginatedurl)
      .then((resp) => {
        dispatch(getPaginated(resp.data));
        dispatch(setPages(parseInt(resp.data.count)));
      })
      .catch((err) => {
        var error = String(err.response.data.name);
        setErrMsg(error);
      });
  }, [page, limit, dispatch, paginatedurl]);

  const items = useSelector((state) => state.driverPaginated.value);
  const data = items.data.rows;
  const [errMsg, setErrMsg] = useState("");

  const viewClicked = (id, name) => {
    navigate("/driversIncidents", { state: { id: id, name: name } });
  };

  const displayData =
    data instanceof Array
      ? data.map((item) => (
          <tr key={item.id}>
            <td>{item.core_driver_id}</td>
            <td>{item.name}</td>
            <td>{item.email}</td>
            <td>{item.phone}</td>
            <td>
              <button
                className="btn btn-secondary"
                data-toggle="modal"
                onClick={() => viewClicked(item.id, item.name)}
              >
                <i className="fa fa-eye"></i>
              </button>
            </td>
          </tr>
        ))
      : null;

  return (
    <div>
      <div className={errMsg ? "errmsg" : "offscreen"} aria-live="assertive">
        {errMsg}
      </div>
      <div className="card table-responsive">
        <table className="table table-hover  ">
          <thead className="card-header">
            <tr>
              <th>Core ID</th>
              <th>DriverName</th>
              <th>DriverEmail</th>
              <th>DriverPhone</th>
              <th></th>
            </tr>
          </thead>
          <tbody>{displayData}</tbody>
        </table>
      </div>
      <Paginator page="drivers" />
    </div>
  );
};

export default Table;
