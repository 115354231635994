import Navbar from "../Navbar";

import { Tabs, Tab, TabPane } from "react-bootstrap";
import Categories from "../categories/Categories";
import Types from "../categories/Types";
import Violations from "../categories/Violations";
import ViolationCategory from "../categories/violationCategories";
const ManageIncident = () => {
  return (
    <div>
      <Navbar Active="incidents" />
      <div className="container">
        <Tabs className="mt-1 text-dark" justify>
          <Tab
            eventKey="category"
            tabClassName="text-dark"
            title="Incident Category"
          >
            <Categories />
          </Tab>
          <Tab
            eventKey="type"
            tabClassName="text-dark"
            title="Incident
           Type"
          >
            <Types />
          </Tab>
          <Tab eventKey="violation" tabClassName="text-dark" title="RootCause">
            <Violations />
          </Tab>
          <Tab
            eventKey="violation_category"
            tabClassName="text-dark"
            title="RootCauseCategory"
          >
            <ViolationCategory />
          </Tab>
        </Tabs>

        <TabPane></TabPane>
      </div>
    </div>
  );
};

export default ManageIncident;
