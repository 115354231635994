import react from "react";
import { Tabs, Tab, TabPane } from "react-bootstrap";
import ManageTypes from "./ManageTypes";
import ManageCategories from "./ManageCategories";
import ManageMeasures from "./ManageMeasures";
import ManageOrganizations from "./ManageOrganizations";
import ManageStatuses from "./ManageStatuses";
import Navbar from "../Navbar";

const ManageAudit = () => {
  return (
    <div>
      <Navbar Active="audits" />
      <div className="container">
        <Tabs className="mt-5 text-dark" justify>
          <Tab eventKey="category" tabClassName="text-dark" title="Category">
            <ManageCategories />
          </Tab>
          <Tab eventKey="measures" tabClassName="text-dark" title="Measure">
            <ManageMeasures />
          </Tab>
          <Tab
            eventKey="organization"
            tabClassName="text-dark"
            title="Organization"
          >
            <ManageOrganizations />
          </Tab>
          <Tab eventKey="type" tabClassName="text-dark" title="Types">
            <ManageTypes />
          </Tab>
          <Tab eventKey="status" tabClassName="text-dark" title="Status">
            <ManageStatuses />
          </Tab>
        </Tabs>

        <TabPane></TabPane>
      </div>
    </div>
  );
};

export default ManageAudit;
