import Navbar from "../Navbar";
import AuditReport from "./AuditReport";
import { Tabs, Tab } from "react-bootstrap";
import IncidentReport from "./IncidentReport";

const ReportGrid = () => {
  return (
    <div>
      <Navbar Active="reports" />
      <div className="container mt-1">
        <Tabs
          className="mt-2 text-dark w-50 custom-tab"
          variant="pills secondary"
          justify
        >
          <Tab eventKey="incident" tabClassName="text-dark" title="Incident">
            <IncidentReport />
          </Tab>
          <Tab eventKey="audit" tabClassName="text-dark" title="Audit">
            <AuditReport />
          </Tab>
        </Tabs>
      </div>
    </div>
  );
};

export default ReportGrid;
