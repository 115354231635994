import { Outlet, useLocation } from "react-router-dom";
import jwt_decode from "jwt-decode";

import Login from "./Login";

function ProtectedRoutes({ allowedRoles }) {
  const location = useLocation();
  const token = localStorage.getItem("jwtToken");
  let isExpired = true;
  let isAuth = "";

  if (token) {
    let decoded = jwt_decode(token);
    isAuth = decoded;

    if (decoded.exp > Date.now() / 1000) {
      isExpired = false;
    }
  }

  const roles = allowedRoles;
  const message = "Login as admin to view page";
  return isExpired === true ? (
    <Login
      state={{ to: location }}
      replace
      message={"Session has Expired. Please login "}
    />
  ) : roles?.includes(isAuth.role_id) ? (
    <Outlet />
  ) : (
    <Login state={{ from: location }} replace message={message} />
  );
}

export default ProtectedRoutes;
