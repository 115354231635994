import { PieChart, Pie, Cell, Legend, LabelList, Sector } from "recharts";
import { useState } from "react";

// const data = [
//   { name: 'Open', value: 400 },
//   { name: 'Closed', value: 300 },
//   { name: 'Postponed', value: 300 },
//   { name: 'Cancelled', value: 200 },
// ];

const COLORS = [
  "#7a0d53",
  "#ef7f1a",
  "#00C49F",
  "#e677cb",
  "#FECD70",
  "#472D2D",
  "#785654",
  "#1b6ae0",
  "#364b6b",
  "#a760b5",
  "#b8469d",
];

const RADIAN = Math.PI / 180;
const renderCustomizedLabel = ({
  cx,
  cy,
  midAngle,
  innerRadius,
  outerRadius,
  percent,
  index,
}) => {
  const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  return (
    <text
      x={x}
      y={y}
      fill="white"
      textAnchor={x > cx ? "start" : "end"}
      dominantBaseline="central"
    >
      {`${(percent * 100).toFixed(0)}%`}
    </text>
  );
};
const renderActiveShape = (props) => {
  console.log(props.name);
  const RADIAN = Math.PI / 180;
  const {
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    startAngle,
    endAngle,
    fill,
    payload,
    percent,
    value,
  } = props;
  const sin = Math.sin(-RADIAN * midAngle);
  const cos = Math.cos(-RADIAN * midAngle);
  const sx = cx + (outerRadius + 10) * cos;
  const sy = cy + (outerRadius + 10) * sin;
  const mx = cx + (outerRadius + 30) * cos;
  const my = cy + (outerRadius + 30) * sin;
  const ex = mx + (cos >= 0 ? 1 : -1) * 22;
  const ey = my;
  const textAnchor = cos >= 0 ? "start" : "end";

  return (
    <g>
      <text x={cx} y={cy} dy={8} textAnchor="middle" fill={fill}>
        {payload.name}
      </text>
      <Sector
        cx={cx}
        cy={cy}
        innerRadius={innerRadius}
        outerRadius={outerRadius}
        startAngle={startAngle}
        endAngle={endAngle}
        fill={fill}
      />
      <Sector
        cx={cx}
        cy={cy}
        startAngle={startAngle}
        endAngle={endAngle}
        innerRadius={outerRadius + 6}
        outerRadius={outerRadius + 10}
        fill={fill}
      />
      {/* <path
        d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`}
        stroke={fill}
        fill="none"
      /> */}
      <circle cx={ex} cy={ey} r={0} fill={fill} stroke="none" />
      <text
        x={ex + (cos >= 0 ? 1 : -1) * 12}
        y={ey}
        textAnchor="middle"
        fill="#333"
        dominantBaseline="middle"
      >{` ${props.name}`}</text>

      {/* <text
        x={ex + (cos >= 0 ? 1 : -1) * 12}
        y={ey}
        dy={18}
        textAnchor="middle"
        fill="#999"
      >
        {`(${(percent * 100).toFixed(2)}%)`} */}
      {/* </text> */}
    </g>
  );
};
const PieChartComponent = ({ payload, namekey, datakey }) => {
  const [activeIndex, setActiveIndex] = useState(null);
  // payload=payload.map(item=>{return {...item,status_count:(parseInt(item.status_count))} });
  const handleMouseEnter = (data, index) => {
    setActiveIndex(index);
  };
  return (
    <PieChart width={350} height={300}>
      <Pie
        activeIndex={activeIndex}
        activeShape={renderActiveShape}
        data={payload}
        dataKey={datakey}
        nameKey={namekey}
        cx="50%"
        cy="50%"
        outerRadius={90}
        fill="#8884d8"
        labelLine={false}
        label={renderCustomizedLabel}
        onMouseEnter={handleMouseEnter}
      >
        {payload.map((entry, index) => (
          <Cell
            key={`cell-${index}`}
            fill={COLORS[index % COLORS.length]}
            // stroke={activeIndex === index ? "#7a0d53" : "none"}
          />
        ))}
      </Pie>
      {/* <Legend layout="vertical" align="left" /> */}
    </PieChart>
  );
};

export default PieChartComponent;
