import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";

import React, { useState } from "react";
import { Modal, Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import logo from "../assets/pontylogo.png";
import account from "../assets/account.png";
import UserAccount from "./users/UserAccount";

const NavbarResponsive = ({ Active }) => {
  const navigate = useNavigate();
  const data = JSON.parse(localStorage.getItem("user"));

  const [show, setShow] = useState(false);
  const AccountsSettings = () => {
    setShow(true);
  };
  const AccountsClose = () => {
    setShow(false);
  };
  return (
    <div>
      <Navbar collapseOnSelect expand="lg" bg="light" variant="light">
        <Container className="d-flex  justify-content-end">
          <Navbar.Brand href="/home">
            <img src={logo} alt="" width="180" height="70" />
          </Navbar.Brand>

          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="me-auto">
              <Nav.Link
                href="/home"
                className={
                  Active === "dashboard "
                    ? "list-group-item-secondary color-me"
                    : "color-me"
                }
              >
                Dashboard
              </Nav.Link>
              {data.role === 1 ? (
                <Nav.Link
                  href="/personnel"
                  className={
                    Active === "users"
                      ? "list-group-item-secondary color-me"
                      : "color-me"
                  }
                >
                  Users
                </Nav.Link>
              ) : null}
              <Nav.Link
                href="/incidents"
                className={
                  Active === "incidents"
                    ? "list-group-item-secondary color-me"
                    : "color-me"
                }
              >
                Events
              </Nav.Link>
              <Nav.Link
                href="/audit"
                className={
                  Active === "audits"
                    ? "list-group-item-secondary color-me"
                    : "color-me"
                }
              >
                Audits
              </Nav.Link>
              <Nav.Link
                href="/Drivers"
                className={
                  Active === "drivers"
                    ? "list-group-item-secondary color-me"
                    : "color-me"
                }
              >
                Drivers
              </Nav.Link>
            </Nav>
          </Navbar.Collapse>
          <div className="d-flex">
            <img
              src={account}
              alt="acc"
              style={{
                float: "right",
                width: "50px",
                height: "50px",
                borderRadius: "50%",
              }}
              type="button"
              onClick={AccountsSettings}
            />
          </div>
        </Container>
      </Navbar>

      <Modal show={show}>
        <Modal.Header>
          <Modal.Title>Account Details</Modal.Title>
          <Button variant="danger" type="submit" onClick={AccountsClose}>
            Close
          </Button>
        </Modal.Header>
        <Modal.Body>
          <UserAccount id={data.no} />
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default NavbarResponsive;
