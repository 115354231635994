import React, { useState, useEffect } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import "./categories.css";
import axios from "../../utils/axios";
import { useSelector, useDispatch } from "react-redux";
import { getTypes } from "../../reducers/incidentTypes";

const Types = () => {
  const dispatch = useDispatch();
  const [allCategories, setAllCategories] = useState("");
  const [allTypes, setallTypes] = useState("");
  const items = useSelector((state) => state.incidentCategories.value.data);
  const types = useSelector((state) => state.incidentTypes.value.data);
  useEffect(() => {
    setAllCategories(items);
    setallTypes(types);
  }, [items, types]);

  const [currentTypes, setcurrentTypes] = useState({
    incident_categories_id: 1,
  });
  const [errMsg, setErrMsg] = useState("");
  const [confMsg, setconfMsg] = useState("");
  const [typeshow, setTypeShow] = useState(false);
  const [typeEditshow, setTypeEditShow] = useState(false);
  const [addType, setaddType] = useState({
    typeCategory: "",
    typeName: "",
    typeDescription: "",
  });
  const { typeName, typeCategory, typeDescription } = addType;

  const getCategory = (id) => {
    for (const val of allCategories) {
      if (val.id === id) return val.name;
    }
    return "none";
  };
  const handleEditShow = (item) => {
    setcurrentTypes(item);
    setTypeEditShow(true);
  };
  const handleTypeConfirm = (e) => {
    e.preventDefault();

    if (isNaN(parseInt(typeCategory.trim()))) {
      setErrMsg(" Category is Required");
    } else if (typeName.trim() === "") {
      setErrMsg(" Name cannot be Empty");
    } else if (typeDescription.trim() === "") {
      setErrMsg(" Description cannot be Empty");
    } else {
      const url = `/incidents/type`;
      axios
        .post(url, addType)
        .then((resp) => {
          setErrMsg("");
          let newval = {
            id: resp?.data?.id,
            name: resp?.data?.name,
            description: resp?.data?.description,
            incident_categories: getCategory(resp.data.incident_categories_id),
          };
          setallTypes((prevTypes) => {
            return [...prevTypes, newval];
          });
          setconfMsg("Incident Type Added Successfully");
          dispatch(getTypes([newval, ...allTypes]));
          setTimeout(() => {
            setconfMsg("");
            setaddType({
              typeCategory: "",
              typeName: "",
              typeDescription: "",
            });
          }, 5000);
        })
        .catch((err) => {
          if (err.response?.data !== undefined) {
            const error = String(err.response.data.error);
            setErrMsg(error);
          } else {
            setErrMsg("A network error occured");
          }
          setconfMsg("");
        });
    }
  };
  const handleTypeEditConfirm = (e) => {
    e.preventDefault();
    if (currentTypes.name.trim() === "") {
      setErrMsg(" Name cannot be Empty");
    } else if (currentTypes.description.trim() === "") {
      setErrMsg(" Description cannot be Empty");
    } else {
      const url = `/incidents/type/${currentTypes.id}`;
      axios
        .patch(url, currentTypes)
        .then((resp) => {
          setconfMsg(resp.data.message);
          setErrMsg("");
          setallTypes((prevtype) => {
            return [
              ...prevtype.map((type) =>
                type.id === currentTypes.id ? (type = currentTypes) : type
              ),
            ];
          });
        })
        .catch((err) => {
          if (err.response.data !== undefined) {
            const error = String(err.response.data.error);
            setErrMsg(error);
          } else {
            setErrMsg("A network error occured");
          }
          setconfMsg("");
        });
    }
  };
  const displayTypes =
    allTypes instanceof Array
      ? allTypes.map((item) => (
          <tr className="card-body" key={item.id}>
            <td>{item.id}</td>
            <td>{item.name}</td>
            <td>{item.incident_categories}</td>
            <td>{item.description}</td>
            <td>
              <button
                className="btn btn-secondary"
                data-toggle="modal"
                onClick={() => handleEditShow(item)}
              >
                <i className="fa fa-pen"></i>
              </button>
            </td>
          </tr>
        ))
      : null;

  const categorynames =
    allCategories instanceof Array
      ? allCategories.map((category) => (
          <option value={category.id} key={category.id}>
            {category.name}
          </option>
        ))
      : null;

  const handleTypeShow = () => setTypeShow(true);
  const handleClose = () => {
    setErrMsg("");
    setconfMsg("");
    setTypeShow(false);
    setTypeEditShow(false);
    setaddType({ typeCategory: "", typeName: "", typeDescription: "" });
  };

  const handleTypeInputChange = (e) => {
    setErrMsg("");
    setconfMsg("");
    setaddType({ ...addType, [e.target.name]: e.target.value });
  };
  const handleTypeEditInputChange = (e) => {
    setErrMsg("");
    setconfMsg("");
    setcurrentTypes({ ...currentTypes, [e.target.name]: e.target.value });
  };
  const category = getCategory(currentTypes.incident_categories_id || "");
  return (
    <div className="mt-5 container table-responsive">
      <button className="btn btn-secondary mb-1" onClick={handleTypeShow}>
        Add
      </button>
      <div className=" card ">
        <table className="table table-hover table-responsive">
          <thead className="card-header">
            <tr>
              <th>#</th>
              <th>Type Name</th>
              <th>Category</th>
              <th>Description</th>
              <th></th>
            </tr>
          </thead>
          <tbody>{displayTypes}</tbody>
        </table>
      </div>
      <Modal show={typeshow}>
        <Modal.Header>
          <Modal.Title>Add Incident Type</Modal.Title>
          <Button variant="danger" type="submit" onClick={handleClose}>
            Close
          </Button>
        </Modal.Header>
        <Modal.Body>
          <div
            className={confMsg ? "confmsg" : "offscreen"}
            aria-live="assertive"
          >
            {confMsg}
          </div>
          <div
            className={errMsg ? "errmsg" : "offscreen"}
            aria-live="assertive"
          >
            {errMsg}
          </div>
          <Form>
            <Form.Group>
              <Form.Label>Select Category</Form.Label>
              <Form.Select
                type="text"
                name="typeCategory"
                value={typeCategory}
                onChange={handleTypeInputChange}
                required
              >
                <option>Select Category</option>
                {categorynames}
              </Form.Select>
            </Form.Group>
            <Form.Group>
              <Form.Label>Name</Form.Label>
              <Form.Control
                type="text"
                name="typeName"
                value={typeName}
                onChange={handleTypeInputChange}
                required
                style={{ textTransform: "capitalize" }}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>Description</Form.Label>
              <Form.Control
                type="text"
                name="typeDescription"
                value={typeDescription}
                onChange={handleTypeInputChange}
                required
              />
            </Form.Group>
            <div className="d-grid gap-6 mt-7 pt-3">
              <button
                type="submit"
                className="btn btn-warning"
                onClick={handleTypeConfirm}
              >
                Save
              </button>
            </div>
          </Form>
        </Modal.Body>
      </Modal>

      <Modal show={typeEditshow}>
        <Modal.Header>
          <Modal.Title>Edit Incident Type</Modal.Title>
          <Button variant="danger" type="submit" onClick={handleClose}>
            Close
          </Button>
        </Modal.Header>
        <Modal.Body>
          <div
            className={confMsg ? "confmsg" : "offscreen"}
            aria-live="assertive"
          >
            {confMsg}
          </div>
          <div
            className={errMsg ? "errmsg" : "offscreen"}
            aria-live="assertive"
          >
            {errMsg}
          </div>
          <Form>
            <Form.Group>
              <Form.Label> Category</Form.Label>
              <Form.Control
                type="text"
                name="category"
                value={currentTypes.incident_categories}
                onChange={handleTypeEditInputChange}
                required
                disabled
              ></Form.Control>
            </Form.Group>
            <Form.Group>
              <Form.Label>Name</Form.Label>
              <Form.Control
                type="text"
                name="name"
                value={currentTypes.name}
                onChange={handleTypeEditInputChange}
                required
                style={{ textTransform: "capitalize" }}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>Description</Form.Label>
              <Form.Control
                type="text"
                name="description"
                value={currentTypes.description}
                onChange={handleTypeEditInputChange}
                required
              />
            </Form.Group>
            <div className="d-grid gap-6 mt-7 pt-3">
              <button
                type="submit"
                className="btn btn-warning"
                onClick={handleTypeEditConfirm}
              >
                Save
              </button>
            </div>
          </Form>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default Types;
