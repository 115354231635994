import { createSlice } from "@reduxjs/toolkit";

const initialcategories = {
  data: [],
  errMsg: "",
};

export const violationCategoriesSlice = createSlice({
  name: "ViolationCategories",
  initialState: { value: initialcategories },
  reducers: {
    getVCategories: (state, action) => {
      state.value.data = action.payload;
    },
    logoutVCategories: (state) => {
      state.value = initialcategories;
    },
  },
});
export const { getVCategories, logoutVCategories } =
  violationCategoriesSlice.actions;
export const selectViolationCategories = (state) => state.violationCategories;
export default violationCategoriesSlice.reducer;
