import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import Navbar from "../Navbar";
import Table from "./Table";

import { getTypes } from "../../reducers/incidentTypes";
import { getAll } from "../../reducers/violations";
import { getAllDrivers } from "../../reducers/drivers";
import { getAllVehicles } from "../../reducers/vehicles";
import { getCategories } from "../../reducers/incidentCategories";
import { getPersonnels } from "../../reducers/personnel";
import { getVCategories } from "../../reducers/violationCategory";
import {
  getDrivers,
  getIncidentCategories,
  getIncidentTypes,
  getIncidentViolations,
  getUsers,
  getVehicles,
  getViolationCategories,
} from "./service";
function Incidents() {
  const dispatch = useDispatch();

  useEffect(() => {
    getIncidentCategories((data) => dispatch(getCategories(data)));
    getIncidentViolations((data) => dispatch(getAll(data)));
    getIncidentTypes((data) => dispatch(getTypes(data)));
    getVehicles((data) => dispatch(getAllVehicles(data)));
    getDrivers((data) => dispatch(getAllDrivers(data)));
    getUsers((data) => dispatch(getPersonnels(data)));
    getViolationCategories((data) => dispatch(getVCategories(data)));
  }, [dispatch]);

  return (
    <div>
      <Navbar Active="incidents" />
      <div className="mt-1 container table-responsive">
        <Table />
      </div>
    </div>
  );
}

export default Incidents;
