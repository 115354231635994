import axios from "../../utils/axios";


export const fetchAuditActions=(id,dispatcher)=>{
    const url = `/actions/${id}`;

    axios
      .get(url)
      .then((res) => {
        dispatcher(res.data);
        
      })
      .catch((err) => {
        console.log(err);
      });
}