/* eslint-disable react-hooks/exhaustive-deps */
import React, { useRef, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useState } from "react";
import { Modal, Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import moment from "moment";

import AddForm from "./AddForm";
import EditForm from "./EditForm";
import { getIncidents } from "../../reducers/incidents";
import Paginator from "../pagination/Paginator";
import { selectPage, selectLimit, setPages } from "../../reducers/pagination";
import { Form } from "react-bootstrap";

import { filterIncidents } from "../report/report.service";
import { exportReport } from "../report/export.service";

const Table = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [incidentData, setIncidentData] = useState(
    useSelector((state) => state.incidents.value.data.rows)
  );
  const page = useSelector(selectPage);
  const records = useSelector(selectLimit);

  const user = JSON.parse(localStorage.getItem("user"));
  const items = useSelector((state) => state.incidents.value.data);
  const item = useSelector((state) => state.incidents.value);
  const v_category = useSelector(
    (state) => state.violationCategories.value.data
  );
  useEffect(() => {
    let params = {
      from: fromDate,
      to: toDate,
      vehicle: filterVehicle,
      driver: filterDriver,
      violation: filterViolation,
      type: filterType,
      limit: records,
      page: page - 1,
    };
    filterIncidents(params, (data) => {
      dispatch(getIncidents(data.rows));
      setIncidentData(data.rows);
      dispatch(setPages(parseInt(data.count)));
    });
  }, [page, records]);
  useEffect(() => {
    setIncidentData(items);
  }, [items]);
  const errRef = useRef();

  const [show, setShow] = useState(false);
  const [editshow, setEditShow] = useState(false);
  const [currentIncident, setCurrentIncident] = useState("");

  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [filterDriver, setFilterDriver] = useState("");
  const [filterVehicle, setFilterVehicle] = useState("");
  const [filterViolation, setFilterViolation] = useState("");
  const [filterType, setFilterType] = useState("");

  const handleFilter = (e) => {
    let params = {
      from: fromDate,
      to: toDate,
      vehicle: filterVehicle,
      driver: filterDriver,
      violation: filterViolation,
      type: filterType,
      limit: records,
      page: page - 1,
    };
    filterIncidents(params, (data) => {
      dispatch(getIncidents(data.rows));
      setIncidentData(data.rows);
    });
  };
  const clearFilter = (e) => {
    let params = {
      from: "",
      to: "",
      vehicle: "",
      driver: "",
      violation: "",
      type: "",
    };
    filterIncidents(params, (data) => {
      dispatch(getIncidents(data.rows));
      setIncidentData(data.rows);
      setFromDate("");
      setToDate("");
      setFilterDriver("");
      setFilterVehicle("");
      setFilterViolation("");
      setFilterType("");
    });
  };
  const handleExport = () => {
    if (incidentData) {
      let data = [];
      incidentData.map((incident) => {
        return data.push({
          id: incident.id,
          rootCauseCategory: getViolationCategory(
            incident.violation_category_id
          ),
          incident_type: incident.incident_type,
          description: incident.description,
          vehicle: incident.vehicle,
          driver: incident.driver,
          rootCause: incident.violation,
          created_by: incident.user,
          event_date: incident.incident_date,
          location: incident.location,
          action: incident.action,
        });
      });
      exportReport(data, "incident_export", "incident");
    } else {
      return;
    }
  };

  const handleShow = () => setShow(true);
  const handleClose = () => {
    setShow(false);
  };
  const handleEditClose = () => {
    setEditShow(false);
  };

  const viewCategories = () => {
    navigate("/incidents/manage");
  };

  //update Incident
  const handleEditShow = (item) => {
    setCurrentIncident(item);
    setEditShow(true);
  };

  document.addEventListener("onDialogClose", () => {
    setShow(false);
  });

  const types = useSelector((state) => state.incidentTypes.value);
  const typesincidents = types.data;

  //drivers
  const driverData = useSelector((state) => state.drivers.value);
  const drivers = driverData.data;

  //violations
  const viols = useSelector((state) => state.violations.value);
  const violations = viols.data;
  //violations
  const peoples = useSelector((state) => state.personells.value);
  const persons = peoples.data;
  //vehicles
  const Vehicless = useSelector((state) => state.vehicles.value);
  const vehicles = Vehicless.data;

  const getVehicle = (id) => {
    return vehicles.map((vehicle) =>
      vehicle.id === id ? vehicle.license_plate_no : null
    );
  };
  const getDriver = (id) => {
    return drivers.map((driver) => (driver.id === id ? driver.name : null));
  };
  const getIncidentType = (id) => {
    return typesincidents.map((typesincident) =>
      typesincident.id === id ? typesincident.name : null
    );
  };
  const getViolation = (id) => {
    if (!id) {
      return "N/A";
    } else {
      return violations.map((violation) =>
        violation.id === id ? violation.name : null
      );
    }
  };
  const getViolationCategory = (id) => {
    if (!id) {
      return "N/A";
    } else {
      let name = "";
      v_category.forEach((v_cat) =>
        v_cat.id === id ? (name = v_cat.name) : null
      );
      return name;
    }
  };
  const getUser = (id) => {
    return persons.map((person) => (person.id === id ? person.name : null));
  };
  const displayCard =
    incidentData?.length > 0 ? (
      incidentData.map((item) => (
        <div className="container card m-2">
          <div className="row" key={item.id}>
            <div className="col-8">
              <div className="card-body p-2">
                <h5> ID : {item.id}</h5>
                <p>Vehicle :{getVehicle(item.vehicle_id)} </p>
                <p>Driver : {getDriver(item.driver_id)}</p>
                <p>Event : {getIncidentType(item.incident_type_id)}</p>
                <p>Description : {item.description}</p>
                <p>
                  RootCause : {getViolation(item.violation_id)} -{" "}
                  {getViolationCategory(item.violation_category_id)}
                </p>
              </div>
            </div>
            <div className="col-4">
              <div className="card-body p-2">
                <p>
                  Event Date :{" "}
                  {moment(item.incident_date).format("MMMM Do YYYY, h:mm a")}
                </p>
                <p>ReportedBy : {getUser(item.created_by)}</p>
                <p>Location : {item.location}</p>
                <p>Action : {item.action}</p>
                <button
                  className="btn btn-secondary"
                  data-toggle="modal"
                  onClick={() => handleEditShow(item)}
                >
                  <i className="fa fa-pen"></i>
                </button>
              </div>
            </div>
          </div>
        </div>
      ))
    ) : (
      <div className="card"> 0 Records</div>
    );

  const displayTasks =
    incidentData instanceof Array ? (
      incidentData.length > 0 ? (
        incidentData.map((item) => (
          <tr className="card-body" key={item.id}>
            <td>{item.id}</td>
            <td>{getVehicle(item.vehicle_id)}</td>
            <td>{getDriver(item.driver_id)}</td>
            <td>{getIncidentType(item.incident_type_id)}</td>
            <td>{item.description}</td>
            <td>
              {getViolation(item.violation_id)} -{" "}
              {getViolationCategory(item.violation_category_id)}
            </td>
            <td>{moment(item.incident_date).format("MMMM Do YYYY, h:mm a")}</td>
            <td>{getUser(item.created_by)}</td>
            <td>{item.location}</td>
            <td>{item.action}</td>
            <td>
              <button
                className="btn btn-secondary"
                data-toggle="modal"
                onClick={() => handleEditShow(item)}
              >
                <i className="fa fa-pen"></i>
              </button>
            </td>
          </tr>
        ))
      ) : (
        <tr className="card-body">
          <td>0 Records </td>
        </tr>
      )
    ) : null;

  return (
    <div>
      <div
        ref={errRef}
        className={item.errMsg ? "errmsg" : "offscreen"}
        aria-live="assertive"
      >
        {item.errMsg}
      </div>
      <div>

        {user.role === 1 ? (
          <Button variant="secondary " onClick={viewCategories}>
            Manage
          </Button>
        ) : null}        <Button
          className="btn btn-secondary m-1"
          // style={{ float: "left" }}
          data-toggle="modal"
          onClick={handleShow}
        >
          Add
        </Button>
        <Button
          className="btn-danger m-1"
          style={{ float: "right" }}
          onClick={handleExport}
          disabled={incidentData ? false : true}
        >
          Export
        </Button>
      </div>

      <div className="card-columns mb-0">
        <div
          className="card mb-0"
          style={{
            borderLeft: "none",
            borderRight: "none",
            borderBottom: "none",
          }}
        >
          <div className="card-body mb-0 ">
            <input
              type="date"
              id="incident_start_date"
              placeholder="From"
              className="report-card"
              value={fromDate}
              onChange={(e) => setFromDate(e.target.value)}
            />
            <span> - </span>
            <input
              type="date"
              id="incident_end_date"
              placeholder="To"
              className="report-card"
              value={toDate}
              onChange={(e) => setToDate(e.target.value)}
            />
            <select
              aria-label="Default select example"
              className="report-select "
              value={filterDriver}
              onChange={(e) => setFilterDriver(e.target.value)}
            >
              <option value="">Select Driver</option>
              {drivers
                ? drivers.map((driver) => (
                    <option value={driver.id} key={driver.id}>
                      {driver.name}
                    </option>
                  ))
                : null}
            </select>
            <select
              aria-label="Default select example"
              className="report-select"
              value={filterVehicle}
              onChange={(e) => setFilterVehicle(e.target.value)}
            >
              <option value="">Select Vehicle</option>
              {vehicles
                ? vehicles.map((vehicle) => (
                    <option key={vehicle.id} value={vehicle.id}>
                      {vehicle.license_plate_no}
                    </option>
                  ))
                : null}
            </select>
            <select
              aria-label="Default select example"
              className="report-select "
              value={filterViolation}
              onChange={(e) => setFilterViolation(e.target.value)}
            >
              <option value="">Select rootCause</option>
              {violations
                ? violations.map((violation) => (
                    <option key={violation.id} value={violation.id}>
                      {violation.name}
                    </option>
                  ))
                : null}
            </select>
            <select
              aria-label="Default select example"
              className="report-select "
              value={filterType}
              onChange={(e) => setFilterType(e.target.value)}
            >
              <option value="">Select Category</option>
              {typesincidents
                ? typesincidents.map((type) => (
                    <option key={type.id} value={type.id}>
                      {type.name}
                    </option>
                  ))
                : null}
            </select>
            <Button
              className="btn-secondary report-button"
              onClick={handleFilter}
            >
              Filter
            </Button>
            <Button
              className="btn btn-secondary report-button"
              data-toggle="modal"
              onClick={clearFilter}
            >
              Clear
            </Button>
          </div>
        </div>
      </div>

      {window.innerWidth > 1200 ? (
        <div className="card  table-responsive mt-0">
          <table className="table table-hover">
            <thead className="card-header">
              <tr>
                <th>ID</th>
                <th>Vehicle</th>
                <th>Driver</th>
                <th>Event</th>
                <th>Description</th>
                <th>RootCause</th>
                <th>EventDate</th>
                <th>ReportedBy</th>
                <th>Location</th>
                <th>Action</th>
                <th></th>
              </tr>
            </thead>
            <tbody>{displayTasks}</tbody>
          </table>
        </div>
      ) : (
        displayCard
      )}

      <Paginator page="incident" records={records} current={page} />

      <Modal show={show}>
        <Modal.Header>
          <Modal.Title>Add Incident</Modal.Title>
          <Button variant="danger" type="submit" onClick={handleClose}>
            Close
          </Button>
        </Modal.Header>
        <Modal.Body>
          <AddForm />
        </Modal.Body>
      </Modal>
      <Modal show={editshow}>
        <Modal.Header>
          <Modal.Title>Edit Incident</Modal.Title>
          <Button variant="danger" type="submit" onClick={handleEditClose}>
            Close
          </Button>
        </Modal.Header>
        <Modal.Body>
          <EditForm Currentincident={currentIncident} />
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default Table;
