import * as Filesaver from "file-saver";
import * as XLSX from "xlsx";
import { useSelector } from "react-redux";
export const exportReport = (data, filename, type) => {
  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";
  const exportToCSV = (apiData, filename) => {
    const ws = XLSX.utils.json_to_sheet(apiData);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    Filesaver.saveAs(data, filename + fileExtension);
  };
  let finalData;
  if (type === "audit") {
    finalData = data.map((item) => {
      return {
        id: item.id,
        name: item.name,
        description: item.description,
        category: item.audit_category_name,
        measure: item.audit_measure_name,
        type: item.audit_type_name,
        responsible: item.accountable,
        created_by: item.created_by,
        date_raised: item.date_raised,
        date_due: item.due_date,
        organization: item.organization_name,
        raised_by: item.raised_by,
        status: item.status_name,
        notes: item.notes,
      };
    });
  } else if (type === "incident") {
    console.log(data);
    finalData = data.map((item) => {
      return {
        id: item.id,
        event: item.incident_type,
        description: item.description,
        vehicle: item.vehicle,
        driver: item.driver,
        rootCause: item.rootCause,
        rootCauseCategory: item.rootCauseCategory,
        created_by: item.created_by,
        event_date: item.event_date,
        location: item.location,
        action: item.action,
      };
    });
  } else {
    return;
  }

  exportToCSV(finalData, filename);
};
