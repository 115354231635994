import { react } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  selectPage,
  setActivePage,
  setLimitPage,
  selectLimit,
  nextPage,
  prevPage,
  selectPageCount,
} from "../../reducers/pagination";
import { Form } from "react-bootstrap";
const Paginator = (props) => {
  const dispatch = useDispatch();
  const handleNextAction = (e) => {
    e.preventDefault();
    dispatch(nextPage({}));
  };

  const handlePrevAction = (e) => {
    e.preventDefault();
    dispatch(prevPage());
  };

  const handleSelectLimit = (e) => {
    e.preventDefault();
    dispatch(setActivePage(1));
    dispatch(setLimitPage(e.target.value));
  };

  const handleSelectPage = (e) => {
    e.preventDefault();
    dispatch(setActivePage(parseInt(e.target.value)));
  };

  let page = useSelector(selectPage);
  let limit = useSelector(selectLimit);
  let pagesCount = useSelector(selectPageCount);
  let options = [];
  for (let i = 1; i <= pagesCount; i++) {
    options.push(
      <option key={i} value={i}>
        {i}
      </option>
    );
  }

  return (
    <div className="my-3 d-flex justify-content-around">
      <form className="d-flex justify-content-around align-items-center w-100">
        <button
          className="btn btn-outline-warning mx-2"
          onClick={handlePrevAction}
          disabled={page === 1 ? true : false}
        >
          Prev
        </button>
        <div className="d-flex">
          <label htmlFor="page-select" className="mx-2">
            Page
          </label>

          <Form.Select
            id="page-select"
            value={page}
            onChange={handleSelectPage}
            size="sm"
          >
            {options.map((option) => {
              return option;
            })}
          </Form.Select>
        </div>
        <button
          className="btn btn-outline-warning"
          onClick={handleNextAction}
          disabled={pagesCount === page ? true : false}
        >
          Next
        </button>
        <div className="d-flex">
          <div>
            <label htmlFor="page-select" className="mx-2">
              Per Page
            </label>
          </div>
          <Form.Select
            id="limit-select"
            className=""
            value={limit}
            onChange={handleSelectLimit}
            size="sm"
          >
            <option value={10}>10</option>
            <option value={30}>30</option>
            <option value={50}>50</option>
            <option value={100}>100</option>
          </Form.Select>
        </div>
      </form>
    </div>
  );
};

export default Paginator;
