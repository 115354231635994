import { Form } from "react-bootstrap";
import { useState, useEffect } from "react";
import axios from "../../utils/axios";
import { fetchAudits } from "./audit.service";
import { useDispatch, useSelector } from "react-redux";
import { getAudits } from "../../reducers/audit";
import { selectLimit, selectPage } from "../../reducers/pagination";
const AddAuditForm = (props) => {
  const [statuses, setStatus] = useState();
  const [categorys, setCategory] = useState();
  const [organizations, setOrganization] = useState();
  const [measures, setMeasure] = useState();
  const [types, setTypes] = useState();
  const [confMsg, setConfMsg] = useState("");
  const [errMsg, setErrMsg] = useState("");
  const [allAudits, setAllAudits] = useState(
    useSelector((state) => state.audits.value.data)
  );

  const fetchAuditTypes = () => {
    const url = "/audits/types";
    try {
      axios.get(url).then((res) => {
        setTypes(res.data);
      });
    } catch {
      setErrMsg("A network error occured");
    }
  };

  const fetchAuditCategories = () => {
    const url = "/audits/category";
    try {
      axios.get(url).then((res) => {
        setCategory(res.data);
      });
    } catch {
      setErrMsg("A network error occured");
    }
  };

  const fetchAuditStatus = () => {
    const url = "/audits/status";
    try {
      axios.get(url).then((res) => {
        setStatus(res.data);
      });
    } catch {
      setErrMsg("A network error occured");
    }
  };

  const fetchAuditMeasures = () => {
    const url = "/audits/measures";
    try {
      axios.get(url).then((res) => {
        setMeasure(res.data);
      });
    } catch {
      setErrMsg("A network error occured");
    }
  };

  const fetchAuditOrganization = () => {
    const url = "/audits/organizations";
    try {
      axios.get(url).then((res) => {
        setOrganization(res.data);
      });
    } catch {
      setErrMsg("A network error occured");
    }
  };
  const dispatch = useDispatch();
  useEffect(() => {
    fetchAuditTypes();
    fetchAuditCategories();
    fetchAuditMeasures();
    fetchAuditStatus();
    fetchAuditOrganization();
  }, []);
  let page = useSelector(selectPage);
  let limit = useSelector(selectLimit);
  const [newaudit, setAudit] = useState({
    auditName: "",
    auditType: "",
    auditDate: "",
    auditDescription: "",
    organizationRequiringAudit: "",
    auditMeasure: "",
    auditCategory: "",
    auditStatus: "",
    auditNotes: "",
    auditDueDate: "",
    auditRaisedBy: "",
    auditAssignedTo: "",
  });

  const handleAuditFormChange = (e) => {
    const updatedAudit = {};
    let auditAttribute = e.target.id;
    updatedAudit[auditAttribute] = e.target.value;
    setAudit({
      ...newaudit,
      ...updatedAudit,
    });
    setErrMsg("");
  };

  const handleAuditFormSubmit = (e) => {
    e.preventDefault();
    const url = "/audits";

    if (!newaudit.auditName.trim()) {
      setErrMsg("Audit name is required");
    } else if (!newaudit.auditDescription.trim()) {
      setErrMsg("Audit description is required");
    } else if (newaudit.auditDescription.replaceAll("  ", "").length > 255) {
      setErrMsg(" Description is too long");
    } else if (!newaudit.auditType) {
      setErrMsg("Audit type is required");
    } else if (
      !newaudit.organizationRequiringAudit &&
      parseInt(newaudit.auditType) !== 1
    ) {
      setErrMsg("Audit organization is required");
    } else if (!newaudit.auditMeasure) {
      setErrMsg("Audit measure is required");
    } else if (!newaudit.auditCategory) {
      setErrMsg("Audit category is required");
    } else if (!newaudit.auditRaisedBy.trim()) {
      setErrMsg("Audit raised by is required");
    } else if (!newaudit.auditDate) {
      setErrMsg("Audit date is required");
    } else if (!newaudit.auditDueDate) {
      setErrMsg("Audit due date is required");
    } else if (!newaudit.auditAssignedTo.trim()) {
      setErrMsg("Audit Assigned to is required");
    } else if (!newaudit.auditStatus) {
      setErrMsg("Audit status is required");
    } else if (newaudit?.auditNotes?.replaceAll("  ", " ").length > 255) {
      setErrMsg(" Notes is too long");
    } else {
      // console.log(newaudit);
      let filterInternal = organizations?.filter((org) =>
        org.name.toLowerCase().includes("pontypridd")
      );
      let filterIntenalType = types?.filter((type) =>
        type.name.includes("Internal")
      );
      let internalType = filterIntenalType[0].id;
      let internalOrganization;
      // console.log(filterInternal);
      // console.log(filterIntenalType);
      // console.log(internalType);
      // console.log(internalOrganization);
      try {
        internalOrganization = filterInternal[0].id;
      } catch (error) {
        setErrMsg("Please add an internal organization");
        return;
      }
      const newData = {
        type: newaudit.auditType,
        organization:
          parseInt(newaudit.auditType) !== parseInt(internalType)
            ? newaudit.organizationRequiringAudit
            : internalOrganization,
        name: newaudit.auditName,
        description: newaudit.auditDescription,
        notes: newaudit.auditNotes.trim() ? newaudit.auditNotes : null,
        date: newaudit.auditDate,
        measure: newaudit.auditMeasure,
        category: newaudit.auditCategory,
        assigned_to: newaudit.auditAssignedTo,
        due_date: newaudit.auditDueDate,
        raised_by: newaudit.auditRaisedBy,
        status: parseInt(newaudit.auditStatus),
        audit_type_id: parseInt(newaudit.auditType),
        audit_category_id: parseInt(newaudit.auditCategory),
        audit_measure_id: parseInt(newaudit.auditMeasure),
        date_raised: newaudit.auditDate,
        organization_id:
          parseInt(newaudit.auditType) !== parseInt(internalType)
            ? newaudit.organizationRequiringAudit
            : internalOrganization,
      };
      axios
        .post(url, newData)
        .then((res) => {
          dispatch(getAudits([res.data, ...allAudits]));
          setConfMsg("Audit Added Successfully");
          setAudit({
            auditName: "",
            auditType: "",
            auditDate: "",
            auditDescription: "",
            organizationRequiringAudit: "",
            auditMeasure: "",
            auditCategory: "",
            auditStatus: "",
            auditNotes: "",
            auditDueDate: "",
            auditRaisedBy: "",
            auditAssignedTo: "",
          });

          setTimeout(() => {
            setConfMsg("");
          }, 2000);

          if (e.target.id === "submitClose") {
            setTimeout(() => props.close(), 1000);
          }
        })
        .catch((err) => {
          // console.log(err);
          if (err.response.data !== undefined) {
            const error = String(err.response.data.error);
            setErrMsg(error);
          } else {
            setErrMsg("A network error occured");
          }
        });
    }
  };

  return (
    <form onSubmit={handleAuditFormSubmit}>
      <div className={confMsg ? "confmsg" : "offscreen"} aria-live="assertive">
        {confMsg}
      </div>
      <div className={errMsg ? "errmsg" : "offscreen"} aria-live="assertive">
        {errMsg}
      </div>
      <div className="">
        <div>
          <label htmlFor="auditName">Audit Name</label>
          <input
            type="text"
            className="form-control"
            id="auditName"
            onChange={handleAuditFormChange}
            value={newaudit.auditName}
          />
        </div>
        <div className="">
          <label htmlFor="auditDescription">Audit description</label>
          <textarea
            type="text"
            className="form-control"
            id="auditDescription"
            onChange={handleAuditFormChange}
            value={newaudit.auditDescription}
          ></textarea>
        </div>
        <div>
          <label htmlFor="auditType">Audit Type</label>
          <Form.Select
            id="auditType"
            onChange={handleAuditFormChange}
            required
            value={newaudit.auditType}
          >
            <option>Select type</option>
            {types?.map((type) => {
              return (
                <option key={type.id} value={type.id}>
                  {type.name}
                </option>
              );
            })}
          </Form.Select>
        </div>

        {newaudit.auditType !== "1" && (
          <div>
            <label htmlFor="organizationRequiringAudit">
              Organization requesting audit
            </label>
            <Form.Select
              id="organizationRequiringAudit"
              onChange={handleAuditFormChange}
              required
              value={newaudit.organizationRequiringAudit}
            >
              <option>Select organization</option>

              {newaudit.auditType === "1"
                ? null
                : organizations
                    ?.filter((org) => !org.name.includes("Ponty"))
                    .map((organization) => {
                      return (
                        <option key={organization.id} value={organization.id}>
                          {organization.name}
                        </option>
                      );
                    })}
            </Form.Select>
          </div>
        )}
        <div>
          <label htmlFor="auditMeasure">Audit Measure</label>
          <Form.Select
            id="auditMeasure"
            onChange={handleAuditFormChange}
            required
            value={newaudit.auditMeasure}
          >
            <option>Select measure</option>
            {measures?.map((measure) => {
              return (
                <option key={measure.id} value={measure.id}>
                  {measure.name}
                </option>
              );
            })}
          </Form.Select>
        </div>

        <div>
          <label htmlFor="auditCategory">Audit Category</label>
          <Form.Select
            id="auditCategory"
            onChange={handleAuditFormChange}
            required
            value={newaudit.auditCategory}
          >
            <option>Select Category</option>

            {categorys?.map((category) => {
              return (
                <option key={category.id} value={category.id}>
                  {category.name}
                </option>
              );
            })}
          </Form.Select>
        </div>
        <div>
          <label htmlFor="auditName">Raised By</label>
          <input
            type="text"
            className="form-control"
            id="auditRaisedBy"
            onChange={handleAuditFormChange}
            value={newaudit.auditRaisedBy}
          />
        </div>

        <div>
          <label htmlFor="auditDate">Date Raised</label>

          <input
            type="date"
            className="form-control"
            id="auditDate"
            onChange={handleAuditFormChange}
            value={newaudit.auditDate}
          />
        </div>

        <div>
          <label htmlFor="auditDate">Date Due</label>

          <input
            type="date"
            className="form-control"
            id="auditDueDate"
            onChange={handleAuditFormChange}
            value={newaudit.auditDueDate}
          />
        </div>
        <div>
          <label htmlFor="auditName">Assigned To</label>
          <input
            type="text"
            className="form-control"
            id="auditAssignedTo"
            onChange={handleAuditFormChange}
            value={newaudit.auditAssignedTo}
          />
        </div>
      </div>
      <div className="">
        <div>
          <label htmlFor="auditStatus">Audit Status</label>
          <Form.Select
            id="auditStatus"
            onChange={handleAuditFormChange}
            required
            value={newaudit.auditStatus}
          >
            <option>Select status</option>

            {statuses?.map((status) => {
              return (
                <option key={status.id} value={status.id}>
                  {status.name}
                </option>
              );
            })}
          </Form.Select>
        </div>
        <div>
          <label htmlFor="auditName">Notes</label>
          <textarea
            className="form-control"
            id="auditNotes"
            onChange={handleAuditFormChange}
            value={newaudit.auditNotes}
          ></textarea>
        </div>
      </div>
      {/* <div>
        <input
          type="submit"
          className="form-control my-3 float-end btn btn-warning"
        />
      </div> */}

      <div className="">
        <button
          className="btn btn-warning my-3"
          id="submitNew"
          onClick={(e) => {
            // setClose(false);
            handleAuditFormSubmit(e);
          }}
        >
          Save and New
        </button>
        <button
          className="btn btn-warning my-3 float-end"
          id="submitClose"
          onClick={(e) => {
            // setClose(true);
            handleAuditFormSubmit(e);
          }}
        >
          Save and Close
        </button>
      </div>
    </form>
  );
};

export default AddAuditForm;
