import React, { useState } from "react";
import { Button } from "react-bootstrap";
import EditAction from "./EditAction";

const Table = ({ data, statuses, audit_id }) => {
  const actions = data;
  const [targetAction, setTargetAction] = useState({});
  const [showEditModal, setShowEditModal] = useState(false);
  return (
    <div>
      {showEditModal && (
        <EditAction
          action={targetAction}
          audit_id={audit_id}
          show={true}
          close={() => setShowEditModal(false)}
        />
      )}
      <table className="table">
        <thead className="card-header">
          <tr>
            <th>id</th>
            <th>Action</th>
            <th>Raised by</th>
            <th>Responsible</th>
            <th>Date raised</th>
            <th>Due Date</th>
            <th>Status</th>
            <th>Comment</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {Array.isArray(actions) &&
            actions.map((action) => (
              <tr key={action.id}>
                <td>{action.id}</td>
                <td>{action.name}</td>
                <td>{action.raised_by}</td>
                <td>{action.assigned_to}</td>
                <td>
                  {new Date(action.date_raised).toLocaleString("en-US", {
                    month: "short",
                    day: "numeric",
                    year: "numeric",
                  })}
                </td>
                <td>
                  {new Date(action.due_date).toLocaleString("en-US", {
                    month: "short",
                    day: "numeric",
                    year: "numeric",
                  })}
                </td>
                <td>
                  {statuses
                    ? statuses.find((status) => status.id === action.status_id)
                        .name
                    : null}
                </td>
                <td>{action.comments || "--"}</td>
                <td>
                  {" "}
                  <Button
                    variant="primary"
                    className=" btn btn-secondary"
                    onClick={(e) => {
                      setShowEditModal(true);
                      setTargetAction(action);
                    }}
                  >
                    <i className="fa fa-pen"></i>
                  </Button>
                </td>
              </tr>
            ))}
          {actions.length === 0 && (
            <tr>
              <td>0 actions</td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};

export default Table;
