import axios from "../../utils/axios";

const typeurl = "/incidents/types";
const categoryurl = "/incidents/categories";
const violurl = "/violations";
const vehiclesurl = "/vehicles";
const driverurl = "/drivers";
const url = "/users/users/all";
const v_c_url = "/violations/category";

export const getIncidentCategories = (dispatcher) => {
  axios
    .get(categoryurl)
    .then((resp) => {
      dispatcher(resp.data);
    })
    .catch((err) => {
      console.log(err);
      var error = String(err.response.data.name);
      return error;
    });
};
export const getIncidentViolations = (dispatcher) => {
  axios
    .get(violurl)
    .then((resp) => {
      dispatcher(resp.data);
    })
    .catch((err) => {
      console.log(err);
      var error = String(err.response.data.name);
      return error;
    });
};
export const getIncidentTypes = (dispatcher) => {
  axios
    .get(typeurl)
    .then((resp) => {
      dispatcher(resp.data);
    })
    .catch((err) => {
      console.log(err);
      var error = String(err.response.data.name);
      return error;
    });
};
export const getVehicles = (dispatcher) => {
  axios
    .get(vehiclesurl)
    .then((resp) => {
      dispatcher(resp.data);
    })
    .catch((err) => {
      console.log(err);
      var error = String(err.response.data.name);
      return error;
    });
};
export const getDrivers = (dispatcher) => {
  axios
    .get(driverurl)
    .then((resp) => {
      dispatcher(resp.data);
    })
    .catch((err) => {
      console.log(err);
      var error = String(err.response.data.name);
      return error;
    });
};
export const getUsers = (dispatcher) => {
  axios
    .get(url)
    .then((resp) => {
      dispatcher(resp.data);
    })
    .catch((err) => {
      console.log(err);
      var error = String(err.response.data.name);
      return error;
    });
};
export const getViolationCategories = (dispatcher) => {
  axios
    .get(v_c_url)
    .then((resp) => {
      dispatcher(resp.data);
    })
    .catch((err) => {
      console.log(err);
      var error = String(err.response.data.name);
      return error;
    });
};
