import React, { useState, useEffect } from "react";
import { Form } from "react-bootstrap";
import PhoneInput from "react-phone-input-2";
import { useSelector, useDispatch } from "react-redux";
import { getPersonnels } from "../../reducers/personnel";
import { validateEmail } from "./service";

import axios from "../../utils/axios";

const EditUserForm = ({ CurrentUser }) => {
  const dispatch = useDispatch();
  const allUser = useSelector((state) => state.personells.value);
  const [allPersonnel] = useState(allUser.data);
  const [errMsg, setErrMsg] = useState("");
  const [confMsg, setconfMsg] = useState("");
  const [user, setCurrentUser] = useState(CurrentUser);

  useEffect(() => {
    setErrMsg("");
    setconfMsg("");
  }, [CurrentUser]);
  //value inputs
  const onInputChange = (e) => {
    setErrMsg("");
    setconfMsg("");
    setCurrentUser({ ...user, [e.target.name]: e.target.value });
  };

  //save edits
  const handleEdit = (e) => {
    e.preventDefault();
    setErrMsg("");

    let userData = user;

    if (user.name.trim() === "") {
      setErrMsg(" Name cannot be Empty");
    } else if (!validateEmail(user.email)) {
      setErrMsg(" Invalid Email");
    } else if (user.phone.length !== 12) {
      setErrMsg("Invalid Phone Number");
    } else if (user.job_title.trim() === "") {
      setErrMsg(" Job cannot be Empty");
    } else if (user.role_id === "") {
      setErrMsg(" Role cannot be Empty");
    } else if (user.is_active === "") {
      setErrMsg(" Status cannot be Empty");
    } else {
      let url = `/users/${user.id}`;
      axios
        .patch(url, userData)
        .then((resp) => {
          setconfMsg(resp.data.message);
          //dispatch all personnels
          let newdata = allPersonnel.map((pers) =>
            pers.id === user.id ? (pers = user) : pers
          );
          dispatch(getPersonnels(newdata));
        })
        .catch((err) => {
          if (err.response.data.error) {
            const error = err.response.data.error;
            setErrMsg(error);
          } else {
            setErrMsg(err.message);
          }
          setconfMsg("");
        });
    }
  };

  const userRoles = useSelector((state) => state.roles.value);
  const allroles = userRoles.data;

  const displayroles =
    allroles instanceof Array
      ? allroles.map((role) => (
          <option key={role.id} value={role.id}>
            {role.name}
          </option>
        ))
      : null;

  return (
    <div>
      <div className={confMsg ? "confmsg" : "offscreen"} aria-live="assertive">
        {confMsg}
      </div>
      <div className={errMsg ? "errmsg" : "offscreen"} aria-live="assertive">
        {errMsg}
      </div>

      <Form>
        <Form.Group>
          <Form.Label>Full Names</Form.Label>
          <Form.Control
            type="text"
            placeholder="Names"
            value={user.name}
            name="name"
            onChange={(e) => onInputChange(e)}
            required
          />
        </Form.Group>
        <Form.Group>
          <Form.Label>Email</Form.Label>
          <Form.Control
            type="text"
            placeholder="Email Address"
            value={user.email}
            name="email"
            onChange={(e) => onInputChange(e)}
            required
          />
        </Form.Group>
        <Form.Group>
          <Form.Label>Phone Number</Form.Label>
          <PhoneInput
            containerStyle={{ width: "100%" }}
            inputStyle={{ width: "100%" }}
            country={"ke"}
            value={user.phone}
            name="phone"
            onChange={(value) => setCurrentUser({ ...user, phone: value })}
            required
            disableDropdown
          />
        </Form.Group>
        <Form.Group>
          <Form.Label>Job Title</Form.Label>
          <Form.Control
            type="text"
            placeholder="e.g hr"
            value={user.job_title}
            name="job_title"
            onChange={(e) => onInputChange(e)}
            required
          />
        </Form.Group>
        <Form.Group>
          <Form.Label>Role</Form.Label>
          <Form.Select
            type="text"
            placeholder="Personnel"
            value={user.role_id}
            name="role_id"
            onChange={(e) => onInputChange(e)}
            required
          >
            {displayroles}
          </Form.Select>
        </Form.Group>
        <Form.Group>
          <Form.Label>Status</Form.Label>
          <Form.Select
            type="text"
            placeholder="Personnel"
            value={user.is_active}
            name="is_active"
            onChange={(e) => onInputChange(e)}
            required
          >
            <option value={true}>Active</option>
            <option value={false}>Inactive</option>
          </Form.Select>
        </Form.Group>
        <div></div>
        <div className="d-grid gap-6 mt-7 pt-3">
          <button
            type="submit"
            className="btn btn-warning"
            onClick={handleEdit}
          >
            Save
          </button>
        </div>
      </Form>
    </div>
  );
};

export default EditUserForm;
