import React, { useState, useEffect } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import "./categories.css";
import axios from "../../utils/axios";
import { getCategories } from "../../reducers/incidentCategories";
import { useDispatch, useSelector } from "react-redux";
import {
  getIncidentCategories,
  getIncidentTypes,
  getIncidentViolations,
  getViolationCategories,
} from "../incidents/service";

import { getTypes } from "../../reducers/incidentTypes";
import { getAll } from "../../reducers/violations";
import { getVCategories } from "../../reducers/violationCategory";

const Categories = () => {
  const dispatch = useDispatch();
  const [allCategories, setAllCategories] = useState("");
  const [errMsg, setErrMsg] = useState("");
  const [confMsg, setconfMsg] = useState("");
  const [show, setShow] = useState(false);
  const [editshow, setEditShow] = useState(false);
  const [currentCategory, setcurrentCategory] = useState("");
  const [notEdited, setNotEdited] = useState("");
  const [addcategory, setAddCategory] = useState({
    name: "",
    description: "",
  });

  const items = useSelector((state) => state.incidentCategories.value.data);

  // const { name, description } = addcategory;
  //CATEGORIES
  useEffect(() => {
    getIncidentCategories((data) => dispatch(getCategories(data)));
    getIncidentViolations((data) => dispatch(getAll(data)));
    getIncidentTypes((data) => dispatch(getTypes(data)));
    getViolationCategories((data) => dispatch(getVCategories(data)));
  }, [dispatch]);
  useEffect(() => {
    setAllCategories(items);
  }, [items]);

  const handleEditShow = (item) => {
    setcurrentCategory(item);
    setNotEdited(item);
    setEditShow(true);
  };

  const handleConfirm = (e) => {
    e.preventDefault();

    if (addcategory.name.trim() === "") {
      setErrMsg(" Category Name cannot be Empty");
    } else if (addcategory.description.trim() === "") {
      setErrMsg(" Category Description cannot be Empty");
    } else {
      const url = `/incidents/category`;
      axios
        .post(url, addcategory)
        .then((resp) => {
          let newval = {
            id: resp?.data?.id,
            name: resp?.data?.name,
            description: resp?.data?.description,
          };
          setAllCategories((prevCats) => {
            return [...prevCats, newval];
          });
          dispatch(getCategories([...allCategories, newval]));
          setconfMsg("Category Added Successfully");
          setTimeout(() => {
            setconfMsg("");
            setAddCategory({ name: "", description: "" });
          }, 5000);
        })
        .catch((err) => {
          setconfMsg("");
          if (err.response.data !== undefined) {
            const error = String(err.response.data.error);
            setErrMsg(error);
          } else {
            setErrMsg("A network error occured");
          }
          setconfMsg("");
        });
    }
  };

  const handleEditConfirm = (e) => {
    e.preventDefault();

    if (currentCategory.name.trim() === "") {
      setErrMsg(" Category Name cannot be Empty");
    } else if (currentCategory.description.trim() === "") {
      setErrMsg(" Category Description cannot be Empty");
    } else {
      const url = `/incidents/category/${currentCategory.id}`;
      axios
        .patch(url, currentCategory)
        .then((resp) => {
          setconfMsg(resp.data.message);
          setAllCategories((prevCats) => {
            return [
              ...prevCats.map((cat) =>
                cat.id === currentCategory.id ? (cat = currentCategory) : cat
              ),
            ];
          });
        })
        .catch((err) => {
          setconfMsg("");
          if (err.response.data !== undefined) {
            const error = String(err.response.data.error);
            setErrMsg(error);
          } else {
            setErrMsg("A network error occured");
          }
          setconfMsg("");
        });
    }
  };
  //categories
  const displayCategories =
    allCategories instanceof Array
      ? allCategories.map((item) => (
          <tr className="card-body" key={item.id}>
            <td>{item.id}</td>
            <td>{item.name}</td>
            <td>{item.description}</td>
            <td>
              <button
                className="btn btn-secondary"
                data-toggle="modal"
                onClick={() => handleEditShow(item)}
              >
                <i className="fa fa-pen"></i>
              </button>
            </td>
          </tr>
        ))
      : null;

  const handleShow = () => setShow(true);
  const handleClose = () => {
    setShow(false);
    setErrMsg("");
    setconfMsg("");
    setAddCategory({ name: "", description: "" });
  };
  const handleEditClose = () => {
    setErrMsg("");
    setconfMsg("");
    setEditShow(false);
    setcurrentCategory(notEdited);
  };
  const handleInputChange = (e) => {
    setErrMsg("");
    setconfMsg("");
    setAddCategory({ ...addcategory, [e.target.name]: e.target.value });
  };
  const handleEditInputChange = (e) => {
    setErrMsg("");
    setconfMsg("");
    setcurrentCategory({ ...currentCategory, [e.target.name]: e.target.value });
  };
  return (
    <div className="mt-5 container table-responsive">
      <button className="btn btn-secondary mb-1" onClick={handleShow}>
        Add
      </button>
      <div className=" card ">
        <table className="table table-hover  table-responsive">
          <thead className="card-header">
            <tr>
              <th>#</th>
              <th>Category Name</th>
              <th>Description</th>
              <th></th>
            </tr>
          </thead>
          <tbody>{displayCategories}</tbody>
        </table>
      </div>
      <Modal show={show}>
        <Modal.Header>
          <Modal.Title>Add Incident Category</Modal.Title>
          <Button variant="danger" type="submit" onClick={handleClose}>
            Close
          </Button>
        </Modal.Header>
        <Modal.Body>
          <div
            className={confMsg ? "confmsg" : "offscreen"}
            aria-live="assertive"
            // style={{ marginTop: "1" }}
          >
            {confMsg}
          </div>
          <div
            className={errMsg ? "errmsg" : "offscreen"}
            aria-live="assertive"
          >
            {errMsg}
          </div>
          <Form>
            <Form.Group>
              <Form.Label>Category Name</Form.Label>
              <Form.Control
                type="text"
                value={addcategory.name}
                name="name"
                onChange={handleInputChange}
                required
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>Description</Form.Label>
              <Form.Control
                type="text"
                name="description"
                value={addcategory.description}
                onChange={handleInputChange}
                required
              />
            </Form.Group>
            <div className="d-grid gap-6 mt-7 pt-3">
              <button
                type="submit"
                className="btn btn-warning"
                onClick={handleConfirm}
              >
                Save
              </button>
            </div>
          </Form>
        </Modal.Body>
      </Modal>
      <Modal show={editshow}>
        <Modal.Header>
          <Modal.Title>Edit Incident Category</Modal.Title>
          <Button variant="danger" type="submit" onClick={handleEditClose}>
            Close
          </Button>
        </Modal.Header>
        <Modal.Body>
          <div
            className={confMsg ? "confmsg" : "offscreen"}
            aria-live="assertive"
            // style={{ marginTop: "1" }}
          >
            {confMsg}
          </div>
          <div
            className={errMsg ? "errmsg" : "offscreen"}
            aria-live="assertive"
          >
            {errMsg}
          </div>
          <Form>
            <Form.Group>
              <Form.Label>Category Name</Form.Label>
              <Form.Control
                type="text"
                value={currentCategory.name}
                name="name"
                onChange={handleEditInputChange}
                required
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>Description</Form.Label>
              <Form.Control
                type="text"
                name="description"
                value={currentCategory.description}
                onChange={handleEditInputChange}
                required
              />
            </Form.Group>
            <div className="d-grid gap-6 mt-7 pt-3">
              <button
                type="submit"
                className="btn btn-warning"
                onClick={handleEditConfirm}
              >
                Save
              </button>
            </div>
          </Form>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default Categories;
