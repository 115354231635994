import axios from "../../utils/axios";

export const fetchAuditTypes = (dispatcher) => {
  const url = "/audits/types";

  axios
    .get(url)
    .then((res) => {
      dispatcher(res.data);
    })
    .catch((err) => {
      console.log(err);
    });
};

export const fetchAuditCategories = (dispatcher) => {
  const url = "/audits/category";

  axios
    .get(url)
    .then((res) => {
      dispatcher(res.data);
    })
    .catch((err) => {
      console.log(err);
    });
};

export const fetchAuditStatus = (dispatcher) => {
  const url = "/audits/status";

  axios
    .get(url)
    .then((res) => {
      dispatcher(res.data);
    })
    .catch((err) => {
      console.log(err);
    });
};

export const fetchAuditMeasures = (dispatcher) => {
  const url = "/audits/measures";

  axios
    .get(url)
    .then((res) => {
      dispatcher(res.data);
    })
    .catch((err) => {
      console.log(err);
    });
};

export const fetchAuditOrganization = (dispatcher) => {
  const url = "/audits/organizations";
  axios
    .get(url)
    .then((res) => {
      dispatcher(res.data);
    })
    .catch((err) => {
      console.log(err);
    });
};

// add audit category
export const addCategory = (data, props, response) => {
  if (props.action === "add") {
    const url = "/audits/category";

    axios
      .post(url, data)
      .then((res) => {
        response(res, null);
      })
      .catch((err) => {
        response(null, err);
      });
  } else if (props.action === "edit") {
    const url = `/audits/category/${props.item.id}`;

    axios
      .patch(url, data)
      .then((res) => {
        response(res, null);
      })
      .catch((err) => {
        response(null, err);
      });
  }
};

//add audit measure

export const addMeasure = (data, props, response) => {
  if (props.action === "add") {
    const url = "/audits/measure";
    axios
      .post(url, data)
      .then((res) => {
        response(res, null);
      })
      .catch((err) => {
        response(null, err);
      });
  } else if (props.action === "edit") {
    const url = `/audits/measure/${props.item.id}`;
    axios
      .patch(url, data)
      .then((res) => {
        response(res, null);
      })
      .catch((err) => {
        response(null, err);
      });
  }
};

//add audit organization

export const addOrganization = (data, props, response) => {
  if (props.action === "add") {
    const url = "/audits/organisation";
    axios
      .post(url, data)
      .then((res) => {
        response(res, null);
      })
      .catch((err) => {
        response(null, err);
      });
  } else if (props.action === "edit") {
    const url = `/audits/organisation/${props.item.id}`;
    axios
      .patch(url, data)
      .then((res) => {
        response(res, null);
      })
      .catch((err) => {
        response(null, err);
      });
  }
};

// add audit type
export const addType = (data, props, response) => {
  if (props.action === "add") {
    const url = "/audits/type";
    axios
      .post(url, data)
      .then((res) => {
        response(res, null);
      })
      .catch((err) => {
        response(null, err);
      });
  } else if (props.action === "edit") {
    const url = `/audits/type/${props.item.id}`;
    axios
      .patch(url, data)
      .then((res) => {
        response(res, null);
      })
      .catch((err) => {
        response(null, err);
      });
  }
};
//add audit status
export const addStatus = (data, props, response) => {
  if (props.action === "add") {
    const url = "/audits/status";
    axios
      .post(url, data)
      .then((res) => {
        response(res, null);
      })
      .catch((err) => {
        response(null, err);
      });
  } else if (props.action === "edit") {
    const url = `/audits/status/${props.item.id}`;
    axios
      .patch(url, data)
      .then((res) => {
        response(res, null);
      })
      .catch((err) => {
        response(null, err);
      });
  }
};

export const fetchAudits = (page, limit, dispatcher) => {
  const url = `/audits?page=${page - 1}&limit=${limit}`;

  axios
    .get(url)
    .then((res) => {
      dispatcher(res.data);
    })
    .catch((err) => {
      console.log(err);
    });
};

export const filterAudits = (params, dispatcher) => {
  let url = `/audits?page=${params.page}&limit=${params.limit}&from=${params.from}&to=${params.to}`;
  if (params.category) {
    url += `&category=${params.category}`;
  }
  if (params.organization) {
    url += `&organisation=${params.organization}`;
  }
  if (params.status) {
    url += `&audit_status=${params.status}`;
  }

  axios
    .get(url)
    .then((res) => {
      dispatcher(res.data);
    })
    .catch((err) => {
      console.log(err);
    });
};

export const getAuditStatusCount = (dispatcher) => {
  const url = `/audits/counts/status`;

  axios
    .get(url)
    .then((res) => {
      dispatcher(res.data);
    })
    .catch((err) => {
      console.log(err);
    });
};
