import {createSlice} from "@reduxjs/toolkit"


const initialStateValue={
    activePage:1,
    pageLimit:10,
    pageNumber:1
};

export const paginationSlice=createSlice(
    {
        name:"pagination",
        initialState:{value:initialStateValue},
        reducers:{
            //actions
            setActivePage:(state,action)=>{
                state.value.activePage=action.payload;
            },
            setLimitPage:(state,action)=>{
                state.value.pageLimit=action.payload;
            },
            prevPage:(state,action)=>{
                state.value.activePage -=1;
            },
            nextPage:(state,action)=>{
                state.value.activePage +=1;
            },
            setPages:(state,action)=>{
                state.value.pageNumber=Math.ceil(action.payload/state.value.pageLimit) 
            },
            logoutPagination:(state)=>{
                state.value={
                    activePage:"",
                    pageLimit:"",
                    pageNumber:""
                };
            },
        }
    }
)

export const { setActivePage,setLimitPage,nextPage,prevPage,setPages,logoutPagination } =paginationSlice.actions
export const selectLimit=(state)=>state.pagination.value.pageLimit;
export const selectPage=(state)=>state.pagination.value.activePage;
export const selectPageCount=(state)=>state.pagination.value.pageNumber;



export default paginationSlice.reducer