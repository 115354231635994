import React, { useState, useEffect } from "react";
import moment from "moment";
import Navbar from "../Navbar";
import PieChartComponent from "./PieChartComponent";
import SimpleLineGraph from "./SimpleLineGraph";
import axios from "../../utils/axios";

const Dashboard = () => {
  const [auditdata, setAudityearly] = useState([]);
  const [incidentdata, setIncidentyearly] = useState([]);
  const [incidentsToday, setInciToday] = useState(0);
  const [incidentsWeek, setInciWeek] = useState(0);
  const [incidentsMonth, setInciMonth] = useState(0);
  const [auditsToday, setAudToday] = useState(0);
  const [auditsWeek, setAudWeek] = useState(0);
  const [auditsMonth, setAudMonth] = useState(0);
  const [auditStatus, setAuditStatus] = useState();
  const [violations, setViolations] = useState();
  const [auditTotal, setAuditTotal] = useState();
  const [violationTotal, setViolationTotal] = useState();
  const [incidentTypes, setIncidentTypes] = useState();
  const [incidentTotal, setIncidentTotal] = useState();
  const [errMsg, setErrMsg] = useState("");
  useEffect(() => {
    const url = `/incidents/count/today`;
    const wurl = `/incidents/count/week`;
    const murl = `/incidents/count/month`;
    axios
      .get(url)
      .then((res) => {
        setInciToday(parseInt(res.data[0].count));
        axios
          .get(wurl)
          .then((Ares) => {
            setInciWeek(parseInt(Ares.data[0].count));
            axios
              .get(murl)
              .then((Mres) => {
                setInciMonth(parseInt(Mres.data[0].count));
              })
              .catch((err) => {
                setErrMsg(err.message);
              });
          })
          .catch((err) => {
            setErrMsg(err.message);
          });
      })
      .catch((err) => {
        setErrMsg(err.message);
      });
  });
  useEffect(() => {
    const url = `/audits/count/today`;
    const wurl = `/audits/count/week`;
    const murl = `/audits/count/month`;
    const yurl = `/audits/count/year`;
    axios
      .get(url)
      .then((res) => {
        setAudToday(parseInt(res.data[0].count));
        axios
          .get(wurl)
          .then((Ares) => {
            setAudWeek(parseInt(Ares.data[0].count));
            axios
              .get(murl)
              .then((Mres) => {
                setAudMonth(parseInt(Mres.data[0].count));
                axios
                  .get(yurl)
                  .then((yres) => {
                    setAudityearly(yres.data);
                    setAudityearly((prev) => {
                      return [
                        ...prev,
                        prev.map(
                          (incimonth) =>
                            (incimonth.name = moment(incimonth.month).format(
                              "MMMM"
                            ))
                        ),
                      ];
                    });
                  })
                  .catch((err) => {
                    setErrMsg(err.message);
                  });
              })
              .catch((err) => {
                setErrMsg(err.message);
              });
          })
          .catch((err) => {
            setErrMsg(err.message);
          });
      })
      .catch((err) => {
        setErrMsg(err.message);
      });
  }, []);
  useEffect(() => {
    const url = `/audits/counts/status`;
    const vurl = `/incidents/counts/violations`;

    axios
      .get(url)
      .then((res) => {
        setAuditStatus(res.data);
        let total = res.data.reduce(
          (sum, obj) => parseInt(obj["status count"]) + sum,
          0
        );

        setAuditTotal(total);
        axios
          .get(vurl)
          .then((res) => {
            setViolations(res.data);
            let total = res.data.reduce(
              (sum, obj) => parseInt(obj["count"]) + sum,
              0
            );

            setViolationTotal(total);
          })
          .catch((err) => {
            setErrMsg(err.message);
          });
      })
      .catch((err) => {
        setErrMsg(err.message);
      });
  }, []);

  useEffect(() => {
    const incident_url = `/incidents/counts/types`;
    const incident_yr = `/incidents/counts/year`;

    axios
      .get(incident_url)
      .then((res) => {
        setIncidentTypes(res.data);
        axios
          .get(incident_yr)
          .then((res) => {
            let total = res.data.reduce(
              (sum, obj) => parseInt(obj["count"]) + sum,
              0
            );
            setIncidentTotal(total);
            setIncidentyearly(res.data);
            setIncidentyearly((prev) => {
              return [
                ...prev,
                prev.map(
                  (incimonth) =>
                    (incimonth.name = moment(incimonth.month).format("MMMM"))
                ),
              ];
            });
          })
          .catch((err) => {
            setErrMsg(err.message);
          });
      })
      .catch((err) => {
        setErrMsg(err.message);
      });
  }, []);

  const preparePayload = (payload, targetKey) => {
    payload = payload.map((item) => {
      return { ...item, count: parseInt(item[targetKey]) };
    });
    return payload;
  };
  return (
    <div>
      <Navbar Active="dashboard " />
      <div className="container mt-2">
        <span className={errMsg ? "errmsg" : "offscreen"} aria-live="assertive">
          {errMsg}
        </span>
        <div className="card-group ">
          <div className="card-body dashboard-card-count  mb-2">
            <h3>Today</h3>

            <span className="d-flex justify-content-between">
              <label>Incidents</label>
              <h4 className={{ margin: "1px 0px" }}>{incidentsToday}</h4>
            </span>
            <span className="d-flex justify-content-between">
              <label>Audits</label>
              <h4 className={{ margin: "1px 0px" }}>{auditsToday}</h4>
            </span>
          </div>
          <div className="card-body dashboard-card-count  mb-2">
            <h3 className="">Last 7 Days</h3>

            <span className="d-flex justify-content-between">
              <label>Incidents</label>
              <h4 className={{ margin: "1px 0px" }}>{incidentsWeek}</h4>
            </span>
            <span className="d-flex justify-content-between">
              <label>Audits</label>
              <h4 className={{ margin: "1px 0px" }}>{auditsWeek}</h4>
            </span>
          </div>
          <div className="card-body dashboard-card-count  mb-2">
            <h3>Last 30 Days</h3>

            <span className="d-flex justify-content-between">
              <label>Incidents</label>
              <h4 className={{ margin: "1px 0px" }}>{incidentsMonth}</h4>
            </span>
            <span className="d-flex justify-content-between">
              <label>Audits</label>
              <h4 className={{ margin: "1px 0px" }}>{auditsMonth}</h4>
            </span>
          </div>
        </div>
        <div className="card-group ">
          {/* flex-wrap justify-content-around mt-3 */}
          <div className="card-body dashboard-card ">
            <h3 className="px-2">
              Incidents
              <span className="float-end ">{incidentTotal}</span>
            </h3>
            {incidentTypes && (
              <PieChartComponent
                payload={preparePayload(incidentTypes, " count")}
                datakey="count"
                namekey="incident_type"
              />
            )}
          </div>
          <div className="card-body dashboard-card ">
            <h3 className="px-2">
              Audits
              <span className="float-end ">{auditTotal}</span>
            </h3>

            {auditStatus && (
              <PieChartComponent
                payload={preparePayload(auditStatus, "status count")}
                datakey="count"
                namekey="status_name"
              />
            )}
          </div>
          <div className="card-body dashboard-card ">
            <h3 className="px-2">
              Violations
              <span className="float-end total ">{violationTotal}</span>
            </h3>
            {violations && (
              <PieChartComponent
                payload={preparePayload(violations, "count")}
                datakey="count"
                namekey="violation"
                className="float-end"
              />
            )}
          </div>
        </div>
        <div className="card-group">
          <div className="card-body dashboard-chart">
            <h2 className="text-center">Incidents</h2>
            <SimpleLineGraph data={incidentdata} />
          </div>
          <div className="card-body dashboard-chart">
            <h2 className="text-center">Audits </h2>
            <SimpleLineGraph data={auditdata} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
