import React, { useState, useEffect } from "react";
import moment from "moment";
import Navbar from "../Navbar";

import axios from "../../utils/axios";
import { useLocation } from "react-router-dom";

const View = () => {
  const location = useLocation();
  const [incident, setIncident] = useState([]);
  const url = `/incidents/${location.state.id}`;
  const name = location.state.name;
  //get all values with driver_id
  useEffect(() => {
    if (incident) {
      axios
        .get(url)
        .then((resp) => {
          const inci = resp.data.rows;
          setIncident(inci);
        })
        .catch((err) => {
          // setErrMsg(err.message);
        });
    }
  }, [incident, url]);
  let displayData = (
    <tr>
      <td>
        <h3>No Incidents for this driver</h3>
      </td>
    </tr>
  );
  if (incident.length > 0) {
    displayData =
      incident instanceof Array
        ? incident.map((item) => (
            <tr className="card-body" key={item.id}>
              <td>{item.violation_id}</td>
              <td>{item.vehicle_id}</td>
              <td>{item.incident_type_id}</td>
              <td>{item.location}</td>
              <td>{moment(item.incident_date).format("MMM DD, YYYY")}</td>
              <td>{item.action}</td>
              <td>{item.created_by}</td>
            </tr>
          ))
        : null;
  }
  return (
    <div>
      <Navbar />
      <div className="mt-1 container">
        <div className="card">
          <div className="card-header">
            <h5>{name} Incidents</h5>
          </div>
          <table className="table table-hover table-responsive">
            <thead>
              <tr className="card-body">
                <th>Violation</th>
                <th>Vehicle</th>
                <th>Incident</th>
                <th>Location</th>
                <th>IncidentDate</th>
                <th>ActionTaken</th>
                <th>ReportedBy</th>
              </tr>
            </thead>
            <tbody className="card-body">{displayData}</tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default View;
