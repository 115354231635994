import React from "react";

import Navbar from "../Navbar";
import Table from "./Table";

const Drivers = () => {
  return (
    <div>
      <Navbar Active="drivers" />
      <div className="mt-1 container table-responsive">
        <Table />
      </div>
    </div>
  );
};

export default Drivers;
