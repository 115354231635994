import axios from "../../utils/axios";

export const fetchDrivers = (dispatcher) => {
  const url = "/drivers/all";

  axios
    .get(url)
    .then((res) => {
      dispatcher(res.data);
    })
    .catch((err) => {
      console.log(err);
    });
};

export const fetchViolations = (dispatcher) => {
  const url = "/violations";

  axios
    .get(url)
    .then((res) => {
      dispatcher(res.data);
    })
    .catch((err) => {
      console.log(err);
    });
};
export const fetchVehicles = (dispatcher) => {
  const url = "/vehicles";

  axios
    .get(url)
    .then((res) => {
      dispatcher(res.data);
    })
    .catch((err) => {
      console.log(err);
    });
};

export const filterIncidents = (params, dispatcher) => {
  let url = `/incidents?page=${params.page}&limit=${params.limit}&from=${params.from}&to=${params.to}`;
  if (params.vehicle) {
    url += `&vehicle=${params.vehicle}`;
  }
  if (params.driver) {
    url += `&driver=${params.driver}`;
  }
  if (params.violation) {
    url += `&violation=${params.violation}`;
  }
  if (params.type) {
    url += `&type=${params.type}`;
  }
  axios
    .get(url)
    .then((res) => {
      dispatcher(res.data);
    })
    .catch((err) => {
      console.log(err);
    });
};
