import React, { useEffect, useState } from "react";
import { fetchAuditTypes } from "./audit.service";
import { useDispatch, useSelector } from "react-redux";
import { setAuditTypes } from "../../reducers/manageAudit";
import { Button } from "react-bootstrap";
import ManageModal from "./ManageModal";
const ManageTypes = () => {
  const dispatch = useDispatch();
  const auditTypes = useSelector((state) => state.manageAudit.value.auditTypes);

  useEffect(() => {
    fetchAuditTypes((payload) => dispatch(setAuditTypes(payload)));
  }, [dispatch]);

  const [modalShow, setModalShow] = useState(false);
  const [modalAction, setModalAction] = useState("");
  const [editItem, setEditItem] = useState({});

  return (
    <div>
      {/* <button
        className="btn btn-secondary my-1"
        onClick={() => {
          setModalAction("add");
          setModalShow(true);
          setEditItem({});
        }}
      >
        Add
      </button> */}
      <ManageModal
        show={modalShow}
        attribute="type"
        action={modalAction}
        item={editItem}
        title="Type"
        close={() => setModalShow(false)}
      />

      <div className="card mt-2">
        <table className="table">
          <thead className="card-header">
            <tr>
              <th>Type id</th>
              <th>Audit Type</th>
              <th>Description</th>
              <th></th>
            </tr>
          </thead>

          <tbody>
            {auditTypes
              ? auditTypes?.map((auditType) => (
                  <tr key={auditType.id}>
                    <td>{auditType.id}</td>
                    <td>{auditType.name}</td>
                    <td>{auditType.description}</td>
                    <td>
                      <Button
                        variant="secondary"
                        onClick={() => {
                          setModalAction("edit");
                          setModalShow(true);
                          setEditItem(auditType);
                        }}
                      >
                        <i className="fa fa-pen"></i>
                      </Button>
                    </td>
                  </tr>
                ))
              : null}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default ManageTypes;
