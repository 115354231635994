import React, { useState } from "react";
import { Button } from "react-bootstrap";
import AddAction from "./AddAction";
import Table from "./Table";
import { selectActions } from "../../reducers/auditActions";
import { useSelector, useDispatch } from "react-redux";
import { useEffect } from "react";
import { fetchAuditActions } from "./action.service";
import { getActions } from "../../reducers/auditActions";
import { getAuditStatuses, setAuditStatuses } from "../../reducers/manageAudit";
import { fetchAuditStatus } from "../audit/audit.service";

const Actions = ({ id }) => {
  const [showModal, setShowModal] = useState(false);
  const actions = useSelector(selectActions);
  const dispatch = useDispatch();

  useEffect(() => {
    fetchAuditStatus((data) => dispatch(setAuditStatuses(data)));

    fetchAuditActions(id, (data) => dispatch(getActions(data)));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const actionStatuses = useSelector(getAuditStatuses);

  return (
    <div className="">
      <h3 className="m-3 text-center">Actions</h3>
      <Button
        variant="primary"
        className=" btn btn-secondary mb-1"
        onClick={(e) => setShowModal(true)}
      >
        Add
      </Button>
      <p>{showModal}</p>
      <AddAction show={showModal} close={() => setShowModal(false)} id={id} />
      <div className="card table-responsive mb-5">
        <Table data={actions} audit_id={id} statuses={actionStatuses} />
      </div>
    </div>
  );
};

export default Actions;
