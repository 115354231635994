import React, { useState, useEffect } from "react";
import { Form } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";

import axios from "../../utils/axios";
import "./add.css";
import "../../App.css";
import { getIncidents } from "../../reducers/incidents";

const AddForm = () => {
  const dispatch = useDispatch();
  const incidents = useSelector((state) => state.incidents.value.data);
  const [errMsg, setErrMsg] = useState("");
  const [confMsg, setconfMsg] = useState("");
  const [search, setSearch] = useState("");
  const [searchCar, setSearchCar] = useState("");
  const [selectedDriver, setSelectedDriver] = useState("");
  const [driver_id, setId] = useState(0);
  const [vehicle_id, setVId] = useState(0);
  const [selectedCar, setSelectedCar] = useState("");
  const [newIncident, setNewIncident] = useState({
    driver: "",
    vehicle: "",
    type: "",
    local: "",
    violation: "",
    date: "",
    time: "",
    description: "",
    action: "",
    violation_category: "",
  });
  const [allincidents] = useState(incidents);
  const {
    type,
    local,
    violation,
    date,
    description,
    action,
    time,
    violation_category,
  } = newIncident;

  useEffect(() => {
    setErrMsg("");
    setconfMsg("");
  }, [newIncident]);

  //incidenttypes
  const types = useSelector((state) => state.incidentTypes.value);
  const violationCategories = useSelector(
    (state) => state.violationCategories.value.data
  );
  const typesincidents = types.data;
  const incitypes =
    typesincidents instanceof Array
      ? typesincidents.map((incis) => (
          <option value={incis.id} key={incis.id}>
            {incis.name}
          </option>
        ))
      : null;

  //drivers
  const driverData = useSelector((state) => state.drivers.value);
  let drivers = driverData.data;
  //vehicles
  const Vehicless = useSelector((state) => state.vehicles.value);
  const vehicles = Vehicless.data;
  //violations
  const viols = useSelector((state) => state.violations.value);
  const violations = viols.data;
  const allviols =
    violations instanceof Array
      ? violations.map((violat) => (
          <option value={violat.id} key={violat.id}>
            {violat.name}
          </option>
        ))
      : null;
  const categoryOptions =
    violationCategories instanceof Array
      ? violationCategories.map((viol) => {
          if (viol.violation_id == parseInt(violation))
            return (
              <option value={viol.id} key={viol.id}>
                {viol.name}
              </option>
            );
        })
      : null;

  let filtered = drivers.filter((item) =>
    item?.name?.toLowerCase().includes(search.toLowerCase())
  );
  let filteredCar = vehicles.filter((item) =>
    item?.license_plate_no?.toLowerCase().includes(searchCar.toLowerCase())
  );

  const onClickItem = (item) => {
    setSearch("");
    setSelectedDriver(item.name);
    setId(item.id);
  };
  const onClickCarItem = (item) => {
    setSearchCar("");
    setSelectedCar(item.license_plate_no);
    setVId(item.id);
  };
  //value inputs
  const onInputChange = (e) => {
    setNewIncident({ ...newIncident, [e.target.name]: e.target.value });
  };

  const onInput = (e) => {
    setSearch(e.target.value);
    setSelectedDriver(e.target.value);
  };
  const onCarInput = (e) => {
    setSearchCar(e.target.value);
    setSelectedCar(e.target.value);
  };

  const clearform = () => {
    setSelectedCar("");
    setSelectedDriver("");

    setNewIncident({
      driver: "",
      vehicle: "",
      type: "",
      local: "",
      violation: "",
      date: "",
      time: "",
      description: "",
      action: "",
    });
    drivers = null;
    setconfMsg("");
  };
  const closePage = () => {
    setErrMsg("");
    setconfMsg("");
    const event = new Event("onDialogClose");
    document.dispatchEvent(event);
  };
  const handleAdd = (e) => {
    e.preventDefault();
    const btn = e.currentTarget.id;
    if (parseInt(vehicle_id) === 0) {
      setErrMsg(" Select vehicle from List");
    } else if (parseInt(driver_id) === 0) {
      setErrMsg(" Select Driver from List");
    } else if (type === "") {
      setErrMsg(" Incident cannot be Empty");
    } else if (local.trim() === "") {
      setErrMsg(" Location cannot be Empty");
    } else if (description.trim() === "") {
      setErrMsg(" Description cannot be Empty");
    } else if (description.replaceAll("  ", "").length > 255) {
      setErrMsg(" Description is too long");
    } else if (action.trim() === "") {
      setErrMsg(" Action cannot be Empty");
    } else if (date === "") {
      setErrMsg(" Date cannot be Empty");
    } else if (time === "") {
      setErrMsg(" Time cannot be Empty");
    } else if (violation != 0 && violation_category == 0) {
      setErrMsg("Select rootCause category");
    } else {
      let url = `/incidents`;

      newIncident["driver"] = driver_id;
      newIncident["vehicle"] = vehicle_id;
      newIncident["violation_category_id"] = parseInt(violation_category);
      // console.log(newIncident);
      // newIncident["icidentime"] = new Date(`${date},${time} `);
      axios
        .post(url, newIncident)
        .then((resp) => {
          dispatch(getIncidents([resp.data, ...allincidents]));
          setconfMsg("Incident Added Succesfully");
          setErrMsg("");
          if (btn === "new") {
            setTimeout(() => {
              clearform();
            }, 2000);
          } else {
            setTimeout(() => {
              closePage();
            }, 2000);
          }
        })
        .catch((err) => {
          if (err.response.data.error) {
            const error = err.response.data.error;
            setErrMsg(error);
          } else {
            setErrMsg(err.message);
          }
          setconfMsg("");
        });
    }
  };
  return (
    <div>
      <div className={confMsg ? "confmsg" : "offscreen"} aria-live="assertive">
        {confMsg}
      </div>
      <div className={errMsg ? "errmsg" : "offscreen"} aria-live="assertive">
        {errMsg}
      </div>
      <div>
        Vehicle
        <div className="wrapper">
          <div className="search">
            <input
              id="car"
              type="search"
              value={selectedCar}
              placeholder="Search Vehicle"
              onChange={onCarInput}
              autoComplete="off"
            />
          </div>
          {searchCar.length > 0 && filteredCar.length > 0 && (
            <ul className="list">
              {filteredCar.map((item) => (
                <li
                  onClick={() => onClickCarItem(item)}
                  value={item.id}
                  key={item.id}
                >
                  {item.license_plate_no}
                </li>
              ))}
            </ul>
          )}
        </div>
        Driver
        <div className="wrapper">
          <div className="search">
            <input
              id="search"
              type="search"
              value={selectedDriver}
              placeholder="Search Driver"
              onChange={onInput}
              autoComplete="off"
            />
          </div>
          {search.length > 0 && filtered.length > 0 && (
            <ul className="list">
              {filtered.map((item) => (
                <li
                  onClick={() => onClickItem(item)}
                  value={item.id}
                  key={item.id}
                >
                  {item.name}
                </li>
              ))}
            </ul>
          )}
        </div>
      </div>
      <Form>
        <Form.Group>
          <Form.Label>Incident</Form.Label>
          <Form.Select
            type="text"
            placeholder="Incident"
            value={type}
            name="type"
            onChange={(e) => onInputChange(e)}
            required
          >
            <option>Select Incident</option>
            {incitypes}
          </Form.Select>
        </Form.Group>
        <Form.Group>
          <Form.Label>Description</Form.Label>
          <Form.Control
            as="textarea"
            rows={3}
            type="text"
            placeholder="Description"
            value={description}
            name="description"
            onChange={(e) => onInputChange(e)}
            required
          />
        </Form.Group>
        <Form.Group>
          <Form.Label>Action</Form.Label>
          <Form.Control
            type="text"
            placeholder="Action"
            value={action}
            name="action"
            onChange={(e) => onInputChange(e)}
            required
          />
        </Form.Group>
        <Form.Group>
          <Form.Label>Location</Form.Label>
          <Form.Control
            type="text"
            placeholder="Where"
            value={local}
            name="local"
            onChange={(e) => onInputChange(e)}
            required
          />
        </Form.Group>
        <Form.Group>
          <Form.Label>RootCause</Form.Label>
          <Form.Select
            type="text"
            placeholder="Violation"
            value={violation}
            name="violation"
            onChange={(e) => onInputChange(e)}
          >
            <option value={0}>Select RootCause</option>
            {allviols}
          </Form.Select>
        </Form.Group>
        {violation != 0 ? (
          <Form.Group>
            <Form.Label>RootCause Category</Form.Label>
            <Form.Select
              type="text"
              placeholder="violation_category"
              value={violation_category}
              name="violation_category"
              onChange={(e) => onInputChange(e)}
            >
              <option value={0}>Select Category</option>
              {categoryOptions}
            </Form.Select>
          </Form.Group>
        ) : null}

        <Form.Label>Incident Date</Form.Label>
        <Form.Group className="d-flex">
          <Form.Control
            type="date"
            value={date}
            name="date"
            placeholder="Select Date"
            onChange={(e) => onInputChange(e)}
            className="form-control"
          />
          <Form.Control
            type="time"
            step="3600"
            value={time}
            name="time"
            placeholder="Select Time"
            onChange={(e) => onInputChange(e)}
            className="form-control"
          />
        </Form.Group>
        <div></div>
        <div className="mt-2">
          <button
            id="new"
            type="submit"
            className="btn btn-warning"
            onClick={handleAdd}
          >
            Save and New
          </button>
          <button
            id="close"
            type="submit"
            className="btn btn-warning"
            style={{ float: "right", text: "bold" }}
            onClick={handleAdd}
          >
            Save and Close
          </button>
        </div>
      </Form>
    </div>
  );
};

export default AddForm;
