import React, { useState, useEffect } from "react";
import { Form } from "react-bootstrap";
import PhoneInput from "react-phone-input-2";
import { useSelector, useDispatch } from "react-redux";
import { getPersonnels } from "../../reducers/personnel";
import { validateEmail } from "./service";

import axios from "../../utils/axios";

const AddUserForm = () => {
  const dispatch = useDispatch();
  const [errMsg, setErrMsg] = useState("");
  const [confMsg, setconfMsg] = useState("");
  const [newPersonnel, setNewPersonnel] = useState({
    name: "",
    email: "",
    status: "",
    role: "",
    job_title: "",
    password: "",
  });

  const { name, email, status, role, job_title, password } = newPersonnel;
  const [phone, setPhone] = useState();
  //personnel
  const peoples = useSelector((state) => state.personells.value);
  const personnel = peoples.data;
  useEffect(() => {
    setErrMsg("");
    setconfMsg("");
  }, [newPersonnel, personnel]);

  //roles
  const userRoles = useSelector((state) => state.roles.value);
  const allroles = userRoles.data;

  const displayroles =
    allroles instanceof Array
      ? allroles.map((role) => (
          <option value={role.id} key={role.id}>
            {role.name}
          </option>
        ))
      : null;

  const clearform = () => {
    setNewPersonnel({
      name: "",
      email: "",
      status: "",
      role: "",
      job_title: "",
      password: "",
    });
    setPhone("+254");
  };

  const onInputChange = (e) => {
    setNewPersonnel({ ...newPersonnel, [e.target.name]: e.target.value });
  };

  const handleClose = () => {
    setErrMsg("");
    setconfMsg("");
    const event = new Event("onDialogClose");
    document.dispatchEvent(event);
  };
  const handleAdd = (e) => {
    e.preventDefault();
    const btn = e.currentTarget.id;
    setErrMsg("");
    if (name.trim() === "") {
      setErrMsg(" Name cannot be Empty");
    } else if (!validateEmail(email)) {
      setErrMsg("Email is Invalid");
    } else if (phone.length !== 12) {
      setErrMsg(" Invalid Phone Number");
    } else if (status === "") {
      setErrMsg(" Status is Required");
    } else if (role === "") {
      setErrMsg(" Role is Required");
    } else if (password.trim() === "") {
      setErrMsg(" Password cannot be Empty");
    } else if (job_title.trim() === "") {
      setErrMsg(" Job Title cannot be Empty");
    } else {
      let url = `/users`;
      newPersonnel.phone = phone;
      axios
        .post(url, newPersonnel)
        .then((resp) => {
          let reqdata = {
            id: resp.data.id,
            name: resp.data.name,
            email: resp.data.email,
            role_id: resp.data.role_id,
            job_title: resp.data.job_title,
            phone: resp.data.phone,
            is_active: resp.data.is_active,
            created_at: resp.data.created_at,
          };
          let newpersonnels = [reqdata, ...personnel];
          dispatch(getPersonnels(newpersonnels));
          if (btn === "new") {
            setTimeout(() => {
              clearform();
            }, 2000);
          }
          if (btn === "close") {
            setTimeout(() => {
              handleClose();
            }, 2000);
          }
          setconfMsg("Personnell Added Successfully");
        })
        .catch((err) => {
          if (err.response.data.error) {
            const error = err.response.data.error;
            setErrMsg(error);
          } else {
            setErrMsg(err.message);
          }
          setconfMsg("");
        });
    }
  };

  return (
    <div>
      <div className={confMsg ? "confmsg" : "offscreen"} aria-live="assertive">
        {confMsg}
      </div>
      <div className={errMsg ? "errmsg" : "offscreen"} aria-live="assertive">
        {errMsg}
      </div>

      <Form>
        <Form.Group>
          <Form.Label>Full Names</Form.Label>
          <Form.Control
            type="text"
            placeholder="Names"
            value={name}
            name="name"
            onChange={(e) => onInputChange(e)}
            required
          />
        </Form.Group>
        <Form.Group>
          <Form.Label>Email</Form.Label>
          <Form.Control
            type="email"
            placeholder="Email Address"
            autoComplete="new-email"
            value={email}
            name="email"
            onChange={(e) => onInputChange(e)}
            required
          />
        </Form.Group>
        <Form.Group>
          <Form.Label>Phone Number</Form.Label>
          <PhoneInput
            containerStyle={{ width: "100%" }}
            inputStyle={{ width: "100%" }}
            country={"ke"}
            value={phone}
            name="phone"
            onChange={(phone) => setPhone(phone)}
            required
            disableDropdown
          />
        </Form.Group>
        <Form.Group>
          <Form.Label>Job Title</Form.Label>
          <Form.Control
            type="text"
            placeholder="e.g hr"
            value={job_title}
            name="job_title"
            onChange={(e) => onInputChange(e)}
            required
          />
        </Form.Group>
        <Form.Group>
          <Form.Label>Password</Form.Label>
          <Form.Control
            type="password"
            placeholder="password"
            autoComplete="new-password"
            value={password}
            name="password"
            onChange={(e) => onInputChange(e)}
            required
          />
        </Form.Group>
        <Form.Group>
          <Form.Label>Role</Form.Label>
          <Form.Select
            type="text"
            placeholder="Personnel"
            value={role}
            name="role"
            onChange={(e) => onInputChange(e)}
            required
          >
            <option>Select Role</option>
            {displayroles}
          </Form.Select>
        </Form.Group>
        <Form.Group>
          <Form.Label>Status</Form.Label>
          <Form.Select
            type="text"
            placeholder="Personnel"
            value={status}
            name="status"
            onChange={(e) => onInputChange(e)}
            required
          >
            <option>Select status</option>
            <option value={1}>Active</option>
            <option value={2}>Inactive</option>
          </Form.Select>
        </Form.Group>
        <div></div>
        <div className="mt-2">
          <button
            id="new"
            type="submit"
            className="btn btn-warning"
            onClick={handleAdd}
          >
            Save and New
          </button>
          <button
            id="close"
            type="submit"
            className="btn btn-warning"
            style={{ float: "right", text: "bold" }}
            onClick={handleAdd}
          >
            Save and Close
          </button>
        </div>
      </Form>
    </div>
  );
};

export default AddUserForm;
