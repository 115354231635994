import { useState } from "react";
import axios from "../../utils/axios";

const ResetPassword = () => {
  const [email, setEmail] = useState("");
  const [errMsg, setError] = useState("");
  const [confMsg, setconfMsg] = useState("");

  const validateEmail = (email) => {
    return email.match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
  };

  const handleResetSubmit = (e) => {
    e.preventDefault();
    if (validateEmail(email)) {
      let url = "/users/email";
      let data = { email: email.toLowerCase() };
      axios
        .post(url, data)
        .then((res) => {
          setconfMsg("An email with the reset link has been sent");
        })
        .catch((err) => {
          const message = String(err.response.data.message);
          setError(message);
        });
    } else if (email.trim() < 1) {
      setError("Email is required");
    } else {
      setError("Email is invalid");
    }
  };

  return (
    <div className="container ">
      <div
        className="mt-5"
        style={{
          width: "30rem",
          height: "10rem",
          margin: "auto",
          textAlign: "center",
        }}
      >
        <h1 className="text-center mt-5">Ponty Safety</h1>
        <p className="mt-4" style={{}}>
          Please provide your account email address. An email will be sent to
          you with a reset Link. Click on the link to set a new password.
          <a href="/">Back To Login</a>
        </p>
        <div
          className={confMsg ? "confmsg" : "offscreen"}
          aria-live="assertive"
        >
          {confMsg}
        </div>
        <form
          onSubmit={handleResetSubmit}
          className="mx-2"
          style={{ marginTop: "2rem" }}
        >
          <div>
            <h6 className="text-left">
              <strong> Forgot password ? </strong>
            </h6>
            <div className="text-danger">{errMsg}</div>
            <input
              type="text"
              id="reset-email"
              className="form-control"
              placeholder="Email"
              value={email}
              onChange={(e) => {
                setError("");
                setconfMsg("");
                setEmail(e.target.value);
              }}
            />
          </div>
          <div>
            <input
              type="submit"
              className="form-control btn btn-warning my-2"
              value="Reset"
            />
          </div>
        </form>
        <p>
          For further assistance, contact
          <span className="text-primary">support@pontypriddholdings.com</span>
        </p>
      </div>
    </div>
  );
};

export default ResetPassword;
