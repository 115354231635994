import { createSlice } from "@reduxjs/toolkit";

const initialStateValue = {
  auditTypes: "",
  auditCategories: "",
  auditMeasures: "",
  auditOrganizations: "",
  auditStatuses: "",
};

export const manageAuditSlice = createSlice({
  name: "manageAudit",
  initialState: { value: initialStateValue },
  reducers: {
    setAuditTypes: (state, action) => {
      state.value.auditTypes = action.payload;
    },
    updateAuditTypes: (state, action) => {
      state.value.auditTypes.push(action.payload);
    },
    setAuditCategories: (state, action) => {
      state.value.auditCategories = action.payload;
    },
    updateAuditCategories: (state, action) => {
      state.value.auditCategories.push(action.payload);
    },
    setAuditMeasures: (state, action) => {
      state.value.auditMeasures = action.payload;
    },
    updateAuditMeasures: (state, action) => {
      state.value.auditMeasures.push(action.payload);
    },
    setAuditOrganizations: (state, action) => {
      state.value.auditOrganizations = action.payload;
    },
    updateAuditOrganizations: (state, action) => {
      state.value.auditOrganizations.push(action.payload);
    },
    setAuditStatuses: (state, action) => {
      state.value.auditStatuses = action.payload;
    },
    updateAuditStatuses: (state, action) => {
      state.value.auditStatuses.push(action.payload);
    },
  },
});

export const {
  setAuditTypes,
  setAuditCategories,
  setAuditMeasures,
  setAuditOrganizations,
  setAuditStatuses,
  updateAuditMeasures,
  updateAuditCategories,
  updateAuditOrganizations,
  updateAuditStatuses,
  updateAuditTypes,
} = manageAuditSlice.actions;
export const getAuditTypes = (state) => state.manageAudit.value.auditTypes;
export const getAuditCategories = (state) =>
  state.manageAudit.value.auditCategories;
export const getAuditMeasures = (state) =>
  state.manageAudit.value.auditMeasures;
export const getAuditOrganizations = (state) =>
  state.manageAudit.value.auditOrganizations;
export const getAuditStatuses = (state) =>
  state.manageAudit.value.auditStatuses;

export default manageAuditSlice.reducer;
