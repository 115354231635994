import React, { useRef, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useState } from "react";
import { Modal, Button } from "react-bootstrap";

import EditUserForm from "./EditUserForm";
import axios from "../../utils/axios";
import { getPersonnels, setErrMsg } from "../../reducers/personnel";
import Paginator from "../pagination/Paginator";
import { selectPage, selectLimit, setPages } from "../../reducers/pagination";
import AddUserForm from "./AddUserForm";

import moment from "moment";
const Table = () => {
  const dispatch = useDispatch();
  const errRef = useRef();

  const items = useSelector((state) => state.personells.value.data);
  const [data, setData] = useState(items);

  const [show, setShow] = useState(false);
  const [editshow, setEditShow] = useState(false);
  const [currentUser, setCurrentUser] = useState("");
  let page = useSelector(selectPage);
  let limit = useSelector(selectLimit);
  const url = `/users?page=${page - 1}&limit=${limit}`;

  useEffect(() => {
    axios
      .get(url)
      .then((resp) => {
        dispatch(getPersonnels(resp.data.rows));
        dispatch(setPages(parseInt(resp.data.count)));
      })
      .catch((err) => {
        dispatch(setErrMsg(err.message));
      });
  }, [page, limit, dispatch, url]);

  useEffect(() => {
    setData(items);
  }, [items]);

  const handleShow = () => setShow(true);
  const handleClose = () => {
    setShow(false);
  };
  const handleEditClose = () => {
    setEditShow(false);
  };

  //update User
  const handleEditShow = (item) => {
    setCurrentUser(item);
    setEditShow(true);
  };

  document.addEventListener("onDialogClose", () => {
    setShow(false);
  });

  const displayPersonnel =
    data instanceof Array
      ? data.map((item) => (
          <tr className="card-body" key={item.id}>
            <td>{item.id}</td>
            <td>{item.name}</td>
            <td>{item.email}</td>
            <td>{item.phone}</td>
            <td>{item.job_title}</td>
            <td>
              {item.is_active == "false" || item.is_active == false
                ? "Inactive"
                : "Active"}
            </td>
            <td>{item.role_id == 1 ? "Admin" : "User"}</td>
            <td>{moment(item.created_at).format("MMM DD, YYYY")}</td>
            <td>
              {item.id != 1 ? (
                <button
                  className="btn btn-secondary"
                  data-toggle="modal"
                  onClick={() => handleEditShow(item)}
                >
                  <i className="fa fa-pen"></i>
                </button>
              ) : null}
            </td>
          </tr>
        ))
      : null;
  return (
    <div>
      <span
        ref={errRef}
        className={items.errMsg ? "errmsg" : "offscreen"}
        aria-live="assertive"
      >
        {items.errMsg}
      </span>
      <div>
        <Button
          className="btn btn-secondary mb-1"
          data-toggle="modal"
          onClick={handleShow}
        >
          Add
        </Button>
      </div>
      <div className="card  table-responsive">
        <table className="table table-hover ">
          <thead className="card-header">
            <tr>
              <th>ID</th>
              <th>Name</th>
              <th>Email</th>
              <th>Phone</th>
              <th>Job Title</th>
              <th>Status</th>
              <th>Role</th>
              <th>Created On</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>{displayPersonnel}</tbody>
        </table>
      </div>
      <Paginator page="incident" />

      <Modal show={show}>
        <Modal.Header>
          <Modal.Title>Add Personnel</Modal.Title>
          <Button variant="danger" type="submit" onClick={handleClose}>
            Close
          </Button>
        </Modal.Header>
        <Modal.Body>
          <AddUserForm />
        </Modal.Body>
      </Modal>
      <Modal show={editshow}>
        <Modal.Header>
          <Modal.Title>Edit User</Modal.Title>
          <Button variant="danger" type="submit" onClick={handleEditClose}>
            Close
          </Button>
        </Modal.Header>
        <Modal.Body>
          <EditUserForm CurrentUser={currentUser} />
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default Table;
