import React, { useEffect, useState } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import { getAll } from "../../reducers/violations";
import axios from "../../utils/axios";
import { getIncidentViolations } from "../incidents/service";

const Violations = () => {
  const dispatch = useDispatch();
  const [AllViolations, setAllViolations] = useState("");
  const [addviolation, setAddViolation] = useState({
    name: "",
    description: "",
    action: "",
  });
  const [errMsg, setErrMsg] = useState("");
  const [confMsg, setconfMsg] = useState("");
  const [show, setShow] = useState(false);
  const [editshow, setEditShow] = useState(false);
  const [currentVal, setCurrentVal] = useState("");
  const url = `violations`;
  const violations = useSelector((state) => state.violations.value.data);
  useEffect(() => {
    setAllViolations(violations);
  }, [violations]);

  const displayViolations =
    AllViolations instanceof Array
      ? AllViolations.map((violation) => (
          <tr className="card-body" key={violation.id}>
            <td>{violation.id}</td>
            <td>{violation.name}</td>
            <td>{violation.notes}</td>
            <td>{violation.proposed_action}</td>
            <td>
              <button
                className="btn btn-secondary"
                data-toggle="modal"
                onClick={() => handleEditShow(violation)}
              >
                <i className="fa fa-pen"></i>
              </button>
            </td>
          </tr>
        ))
      : null;
  const showModal = () => {
    setShow(true);
  };
  const closeModal = () => {
    setShow(false);
    setErrMsg("");
    setconfMsg("");
    setEditShow(false);
    setAddViolation({
      name: "",
      description: "",
      action: "",
    });
  };
  const handleInputChange = (e) => {
    setErrMsg("");
    setconfMsg("");
    setAddViolation({ ...addviolation, [e.target.name]: e.target.value });
  };
  const handleEditShow = (item) => {
    setEditShow(true);
    setCurrentVal(item);
  };
  const handleEditInputChange = (e) => {
    setErrMsg("");
    setconfMsg("");
    setCurrentVal({ ...currentVal, [e.target.name]: e.target.value });
  };
  const saveViolation = (e) => {
    e.preventDefault();
    if (addviolation.name === "") {
      setErrMsg(" Name cannot be Empty");
    } else if (addviolation.description === "") {
      setErrMsg(" Description cannot be Empty");
    } else if (addviolation.action === "") {
      setErrMsg(" Action cannot be Empty");
    } else {
      axios
        .post(url, addviolation)
        .then((resp) => {
          setErrMsg("");
          setconfMsg("RootCause Added Succesfully");
          setAllViolations((prev) => {
            return [...prev, resp.data];
          });

          getIncidentViolations((data) => dispatch(getAll(data)));
          setTimeout(() => {
            setconfMsg("");
            setAddViolation({
              name: "",
              description: "",
              action: "",
            });
          }, 5000);
        })
        .catch((err) => {
          if (err.response.data.message) {
            const error = err.response.data.message;
            setErrMsg(error);
          } else {
            setErrMsg(err.message);
          }
          setconfMsg("");
        });
    }
  };
  const EditViolation = (e) => {
    const editurl = `violations/${currentVal.id}`;
    e.preventDefault();
    if (currentVal.name.trim() === "") {
      setErrMsg(" Name cannot be Empty");
    } else if (currentVal.notes.trim() === "") {
      setErrMsg(" Description cannot be Empty");
    } else if (currentVal.proposed_action.trim() === "") {
      setErrMsg(" Action cannot be Empty");
    } else {
      axios
        .patch(editurl, currentVal)
        .then((resp) => {
          setErrMsg("");
          setconfMsg("RootCause Edited Succesfully");
          setAllViolations((prevVal) => {
            return [
              ...prevVal.map((viol) =>
                viol.id === currentVal.id ? currentVal : viol
              ),
            ];
          });
        })
        .catch((err) => {
          if (err.response.data.error) {
            const error = err.response.data.error;
            setErrMsg(error);
          } else {
            setErrMsg(err.message);
          }
          setconfMsg("");
        });
    }
  };
  return (
    <div className="mt-5 container table-responsive">
      <button className="btn btn-secondary mb-1" onClick={showModal}>
        Add
      </button>
      <div className=" card ">
        <table className="table table-hover table-responsive">
          <thead className="card-header">
            <tr>
              <th>#</th>
              <th>RootCause</th>
              <th>Description</th>
              <th>Proposed Action</th>
              <th></th>
            </tr>
          </thead>
          <tbody>{displayViolations}</tbody>
        </table>
      </div>
      <Modal show={show}>
        <Modal.Header>
          <Modal.Title>Add RootCause</Modal.Title>
          <Button variant="danger" type="submit" onClick={closeModal}>
            Close
          </Button>
        </Modal.Header>
        <Modal.Body>
          <div
            className={confMsg ? "confmsg" : "offscreen"}
            aria-live="assertive"
          >
            {confMsg}
          </div>
          <div
            className={errMsg ? "errmsg" : "offscreen"}
            aria-live="assertive"
          >
            {errMsg}
          </div>
          <Form>
            <Form.Group>
              <Form.Label> Name</Form.Label>
              <Form.Control
                type="text"
                name="name"
                onChange={handleInputChange}
                value={addviolation.name}
                required
                style={{ textTransform: "capitalize" }}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>Description</Form.Label>
              <Form.Control
                type="text"
                name="description"
                value={addviolation.description}
                onChange={handleInputChange}
                required
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>Proposed Action</Form.Label>
              <Form.Control
                type="text"
                name="action"
                value={addviolation.action}
                onChange={handleInputChange}
                required
                style={{ textTransform: "capitalize" }}
              />
            </Form.Group>
            <Form.Group></Form.Group>
            <div className="d-grid gap-6 mt-7 pt-3">
              <button
                type="submit"
                className="btn btn-warning"
                onClick={saveViolation}
              >
                Save
              </button>
            </div>
          </Form>
        </Modal.Body>
      </Modal>
      <Modal show={editshow}>
        <Modal.Header>
          <Modal.Title>Edit </Modal.Title>
          <Button variant="danger" type="submit" onClick={closeModal}>
            Close
          </Button>
        </Modal.Header>
        <Modal.Body>
          <div
            className={confMsg ? "confmsg" : "offscreen"}
            aria-live="assertive"
          >
            {confMsg}
          </div>
          <div
            className={errMsg ? "errmsg" : "offscreen"}
            aria-live="assertive"
          >
            {errMsg}
          </div>
          <Form>
            <Form.Group>
              <Form.Label> Name</Form.Label>
              <Form.Control
                type="text"
                name="name"
                value={currentVal.name}
                onChange={handleEditInputChange}
                required
                style={{ textTransform: "capitalize" }}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>Description</Form.Label>
              <Form.Control
                type="text"
                name="notes"
                value={currentVal.notes}
                onChange={handleEditInputChange}
                required
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>Proposed Action</Form.Label>
              <Form.Control
                type="text"
                name="proposed_action"
                value={currentVal.proposed_action}
                onChange={handleEditInputChange}
                required
                style={{ textTransform: "capitalize" }}
              />
            </Form.Group>
            <Form.Group></Form.Group>
            <div className="d-grid gap-6 mt-7 pt-3">
              <button
                type="submit"
                className="btn btn-warning"
                onClick={EditViolation}
              >
                Save
              </button>
            </div>
          </Form>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default Violations;
