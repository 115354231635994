import React, { useEffect, useState } from "react";
import { fetchAuditCategories } from "./audit.service";
import { useDispatch, useSelector } from "react-redux";
import {
  setAuditCategories,
  getAuditCategories,
} from "../../reducers/manageAudit";
import { Button } from "react-bootstrap";
import ManageModal from "./ManageModal";

const ManageCategories = () => {
  const dispatch = useDispatch();
  let auditCategories = useSelector(getAuditCategories);

  useEffect(() => {
    fetchAuditCategories((payload) => dispatch(setAuditCategories(payload)));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [modalShow, setModalShow] = useState(false);
  const [modalAction, setModalAction] = useState("");
  const [editItem, setEditItem] = useState({});

  return (
    <div>
      <button
        className="btn btn-secondary my-1"
        onClick={() => {
          setModalAction("add");
          setModalShow(true);
          setEditItem({});
        }}
      >
        Add
      </button>
      <ManageModal
        show={modalShow}
        attribute="category"
        action={modalAction}
        item={editItem}
        title="Category"
        close={() => setModalShow(false)}
      />
      <div className="card mb-5">
        <table className="table">
          <thead className="card-header">
            <tr>
              <th>Category id</th>
              <th>Audit Category</th>
              <th>Description</th>
              <th></th>
            </tr>
          </thead>

          <tbody>
            {auditCategories
              ? auditCategories?.map((auditCategory) => (
                  <tr key={auditCategory.id}>
                    <td>{auditCategory.id}</td>
                    <td>{auditCategory.name}</td>
                    <td>{auditCategory?.description}</td>
                    <td>
                      <Button
                        variant="secondary"
                        onClick={() => {
                          setModalAction("edit");
                          setModalShow(true);
                          setEditItem(auditCategory);
                        }}
                      >
                        <i className="fa fa-pen"></i>
                      </Button>
                    </td>
                  </tr>
                ))
              : null}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default ManageCategories;
