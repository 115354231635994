import React, { useState, useEffect } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import "./categories.css";
import axios from "../../utils/axios";
import { useSelector, useDispatch } from "react-redux";
import { getVCategories } from "../../reducers/violationCategory";
import {
  getIncidentViolations,
  getViolationCategories,
} from "../incidents/service";
import { getAll } from "../../reducers/violations";

const ViolationCategory = () => {
  const dispatch = useDispatch();
 const items = useSelector((state) => state.violations.value.data);
  useEffect(() => {
    getIncidentViolations((data) => {
      setallViolations(data);
      dispatch(getAll(data));
    });
    getViolationCategories((data) => {
      setAllViolationCategories(data);
      dispatch(getVCategories(data));
    });
  }, []);
   useEffect(() => {
    setallViolations(items);
  }, [items]);

  const [allViolationCategories, setAllViolationCategories] = useState("");
  const [allViolations, setallViolations] = useState("");
  const [currentCategory, setCurrentCategory] = useState();
  const [errMsg, setErrMsg] = useState("");
  const [confMsg, setconfMsg] = useState("");
  const [categoryshow, setCategoryShow] = useState(false);
  const [categoryEditshow, setCategoryEditShow] = useState(false);
  const [addCategory, setaddCategory] = useState({
    violationCategory: "",
    name: "",
    description: "",
  });
  const { name, violationCategory, description } = addCategory;

  const getViolation = (id) => {
    for (const val of allViolations) {
      if (val.id === id) return val.name;
    }
    return "none";
  };
  const handleEditShow = (item) => {
    // console.log(item);
    setCurrentCategory(item);
    setCategoryEditShow(true);
  };
  const handleViolationConfirm = (e) => {
    e.preventDefault();
    if (isNaN(parseInt(violationCategory.trim()))) {
      setErrMsg(" Category is Required");
    } else if (name.trim() === "") {
      setErrMsg(" Name cannot be Empty");
    } else if (description.trim() === "") {
      setErrMsg(" Description cannot be Empty");
    } else {
      const url = `/violations/category`;
      const data = {
        name: addCategory.name,
        description: addCategory.description,
        violation_id: parseInt(addCategory.violationCategory),
      };
      axios
        .post(url, data)
        .then((resp) => {
          setErrMsg("");
          setAllViolationCategories((prevTypes) => {
            return [...prevTypes, resp.data];
          });
          setconfMsg(" Category Added Successfully");
          dispatch(getVCategories([resp.data, ...allViolationCategories]));

          setTimeout(() => {
            setconfMsg("");
            setaddCategory({
              violationCategory: "",
              name: "",
              description: "",
            });
          }, 5000);
        })
        .catch((err) => {
          if (err.response?.data !== undefined) {
            const error = String(err.response.data.error);
            setErrMsg(error);
          } else {
            setErrMsg("A network error occured");
          }
          setconfMsg("");
        });
    }
  };
  const handleViolationEditConfirm = (e) => {
    e.preventDefault();
    if (currentCategory.name.trim() === "") {
      setErrMsg(" Name cannot be Empty");
    } else if (currentCategory.description.trim() === "") {
      setErrMsg(" Description cannot be Empty");
    } else {
      const url = `/violations/category/${currentCategory.id}`;
      axios
        .patch(url, currentCategory)
        .then((resp) => {
          setconfMsg("Category Edited Successfully");
          setErrMsg("");
          setAllViolationCategories((prevtype) => {
            return [
              ...prevtype.map((type) =>
                type.id === currentCategory.id ? (type = currentCategory) : type
              ),
            ];
          });
        })
        .catch((err) => {
          // console.log(err);
          if (err.response.data !== undefined) {
            const error = String(err.response.data.error);
            setErrMsg(error);
          } else {
            setErrMsg("A network error occured");
          }
          setconfMsg("");
        });
    }
  };
  const displayViolationCategories =
    allViolationCategories instanceof Array
      ? allViolationCategories.map((item) => (
          <tr className="card-body" key={item.id}>
            <td>{item.id}</td>
            <td>{item.name}</td>
            <td>{getViolation(item.violation_id)}</td>
            <td>{item.description}</td>
            <td>
              <button
                className="btn btn-secondary"
                data-toggle="modal"
                onClick={() => handleEditShow(item)}
              >
                <i className="fa fa-pen"></i>
              </button>
            </td>
          </tr>
        ))
      : null;

  const violationnames =
    allViolations instanceof Array
      ? allViolations.map((violation) => (
          <option value={violation.id} key={violation.id}>
            {violation.name}
          </option>
        ))
      : null;

  const handleClose = () => {
    setErrMsg("");
    setconfMsg("");
    setCategoryShow(false);
    setCategoryEditShow(false);
    setaddCategory({ violationCategory: "", name: "", description: "" });
  };

  const handleViolationInputChange = (e) => {
    setErrMsg("");
    setconfMsg("");
    setaddCategory({ ...addCategory, [e.target.name]: e.target.value });
  };
  const handleViolationEditInputChange = (e) => {
    setErrMsg("");
    setconfMsg("");
    setCurrentCategory({ ...currentCategory, [e.target.name]: e.target.value });
  };
  return (
    <div className="mt-5 container table-responsive">
      <button
        className="btn btn-secondary mb-1"
        onClick={() => setCategoryShow(true)}
      >
        Add
      </button>
      <div className=" card ">
        <table className="table table-hover table-responsive">
          <thead className="card-header">
            <tr>
              <th>#</th>
              <th>Category Name</th>
              <th>RootCause</th>
              <th>Description</th>
              <th></th>
            </tr>
          </thead>
          <tbody>{displayViolationCategories}</tbody>
        </table>
      </div>
      <Modal show={categoryshow}>
        <Modal.Header>
          <Modal.Title>Add RootCause Category</Modal.Title>
          <Button variant="danger" type="submit" onClick={handleClose}>
            Close
          </Button>
        </Modal.Header>
        <Modal.Body>
          <div
            className={confMsg ? "confmsg" : "offscreen"}
            aria-live="assertive"
          >
            {confMsg}
          </div>
          <div
            className={errMsg ? "errmsg" : "offscreen"}
            aria-live="assertive"
          >
            {errMsg}
          </div>
          <Form>
            <Form.Group>
              <Form.Label>Select Category</Form.Label>
              <Form.Select
                type="text"
                name="violationCategory"
                value={violationCategory}
                onChange={handleViolationInputChange}
                required
              >
                <option>Select RootCause</option>
                {violationnames}
              </Form.Select>
            </Form.Group>
            <Form.Group>
              <Form.Label>Name</Form.Label>
              <Form.Control
                type="text"
                name="name"
                value={name}
                onChange={handleViolationInputChange}
                required
                style={{ textTransform: "capitalize" }}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>Description</Form.Label>
              <Form.Control
                type="text"
                name="description"
                value={description}
                onChange={handleViolationInputChange}
                required
              />
            </Form.Group>
            <div className="d-grid gap-6 mt-7 pt-3">
              <button
                type="submit"
                className="btn btn-warning"
                onClick={handleViolationConfirm}
              >
                Save
              </button>
            </div>
          </Form>
        </Modal.Body>
      </Modal>

      <Modal show={categoryEditshow}>
        <Modal.Header>
          <Modal.Title>Edit RootCause Category</Modal.Title>
          <Button variant="danger" type="submit" onClick={handleClose}>
            Close
          </Button>
        </Modal.Header>
        <Modal.Body>
          <div
            className={confMsg ? "confmsg" : "offscreen"}
            aria-live="assertive"
          >
            {confMsg}
          </div>
          <div
            className={errMsg ? "errmsg" : "offscreen"}
            aria-live="assertive"
          >
            {errMsg}
          </div>
          <Form>
            <Form.Group>
              <Form.Label> RootCause</Form.Label>
              <Form.Control
                type="text"
                name="violation"
                value={getViolation(currentCategory?.violation_id)}
                onChange={handleViolationEditInputChange}
                required
                disabled
              ></Form.Control>
            </Form.Group>
            <Form.Group>
              <Form.Label>Name</Form.Label>
              <Form.Control
                type="text"
                name="name"
                value={currentCategory?.name}
                onChange={handleViolationEditInputChange}
                required
                style={{ textTransform: "capitalize" }}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>Description</Form.Label>
              <Form.Control
                type="text"
                name="description"
                value={currentCategory?.description}
                onChange={handleViolationEditInputChange}
                required
              />
            </Form.Group>
            <div className="d-grid gap-6 mt-7 pt-3">
              <button
                type="submit"
                className="btn btn-warning"
                onClick={handleViolationEditConfirm}
              >
                Save
              </button>
            </div>
          </Form>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default ViolationCategory;
