import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import { getAudits } from "../../reducers/audit";
import {
  filterAudits,
  fetchAuditCategories,
  fetchAuditOrganization,
  fetchAuditStatus,
} from "../audit/audit.service";
import { exportReport } from "./export.service";
import { Form } from "react-bootstrap";

const AuditReport = () => {
  const dispatch = useDispatch();
  const [auditCategories, setAuditCategories] = useState([]);
  const [auditOrganizations, setAuditOrganizations] = useState([]);
  const [auditStatuses, setAuditStatuses] = useState([]);
  useEffect(() => {
    fetchAuditCategories((data) => setAuditCategories(data));
    fetchAuditOrganization((data) => setAuditOrganizations(data));
    fetchAuditStatus((data) => setAuditStatuses(data));
  }, []);

  const auditItems = useSelector((state) => state.audits.value);
  let audits = auditItems.data.rows;

  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [filterCategory, setFilterCategory] = useState("");
  const [filterOrganization, setFilterOrganization] = useState("");
  const [filterStatus, setFilterStatus] = useState("");

  const handleFilter = (e) => {
    let params = {
      from: fromDate,
      to: toDate,
      category: filterCategory,
      organization: filterOrganization,
      status: filterStatus,
    };
    filterAudits(params, (data) => dispatch(getAudits(data)));
  };
  const handleExport = () => {
    if (audits) {
      exportReport(audits, "audit_export", "audit");
    } else {
      return;
    }
  };

  return (
    <div className="container car mt-5">
      <form className="my-3 d-flex justify-content-between align-items-center">
        <input
          type="date"
          id="start_date"
          onChange={(e) => setFromDate(e.target.value)}
          placeholder="From"
          className="form-control mx-1"
        />
        <span>-</span>
        <input
          type="date"
          id="end_date"
          onChange={(e) => setToDate(e.target.value)}
          placeholder="To"
          className="form-control mx-1"
        />
        <Form.Select
          aria-label="Default select example"
          className="form-control mx-1"
          onChange={(e) => setFilterCategory(e.target.value)}
        >
          <option value="">Select Category</option>

          {auditCategories
            ? auditCategories.map((category) => (
                <option value={category.id} key={category.id}>
                  {" "}
                  {category.name}
                </option>
              ))
            : null}
        </Form.Select>
        <Form.Select
          aria-label="Default select example"
          className="form-control mx-1"
          onChange={(e) => setFilterOrganization(e.target.value)}
        >
          <option value="">Select Organization</option>
          {auditOrganizations
            ? auditOrganizations.map((organization) => (
                <option value={organization.id} key={organization.id}>
                  {organization.name}
                </option>
              ))
            : null}
        </Form.Select>
        <Form.Select
          aria-label="Default select example"
          className="form-control mx-1"
          onChange={(e) => setFilterStatus(e.target.value)}
        >
          <option value="">Select Status</option>
          {auditStatuses
            ? auditStatuses.map((status) => (
                <option value={status.id} key={status.id}>
                  {status.name}
                </option>
              ))
            : null}
        </Form.Select>

        <Button className="btn-secondary mx-1" onClick={(e) => handleFilter(e)}>
          Filter
        </Button>
        <Button
          className="btn-warning mx-1"
          onClick={handleExport}
          disabled={audits ? false : true}
        >
          Export
        </Button>
      </form>
      <div className="table-responsive card">
        <table className="table table-borderless">
          <thead className="card-header">
            <tr>
              <th>Id</th>
              <th>Audit Name</th>
              <th>Description</th>
              <th>Category</th>
              <th>Type</th>
              <th>Raised By</th>
              <th>Date Raised</th>
              <th>Due Date</th>
              <th>Responsible</th>
              <th>Organization</th>
              <th>Measure</th>
              <th>Status</th>
            </tr>
          </thead>
          <tbody>
            {audits?.map((audit) => (
              <tr key={audit.id} style={{}}>
                <td>{audit.id}</td>
                <td>{audit.name}</td>
                <td>{audit.description}</td>

                <td>{audit.audit_category_name}</td>
                <td>{audit.audit_type_name}</td>
                <td>{audit.raised_by}</td>

                <td>
                  {new Date(audit.date_raised).toLocaleString("en-US", {
                    month: "short",
                    day: "numeric",
                    year: "numeric",
                  })}
                </td>
                <td>
                  {new Date(audit.due_date).toLocaleString("en-US", {
                    month: "short",
                    day: "numeric",
                    year: "numeric",
                  })}
                </td>
                <td>{audit.accountable}</td>
                <td>{audit.organization_name}</td>
                <td>{audit.audit_measure_name}</td>
                <td>{audit.status_name}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default AuditReport;
