import { configureStore } from "@reduxjs/toolkit";
import driverPaginated from "./reducers/driverPaginated";

import drivers from "./reducers/drivers";
import incidents from "./reducers/incidents";
import incidentTypes from "./reducers/incidentTypes";
import userReducer from "./reducers/user";
import audits from "./reducers/audit";
import vehicles from "./reducers/vehicles";
import violations from "./reducers/violations";
import pagination from "./reducers/pagination";
import manageAudit from "./reducers/manageAudit";
import IncidentCategories from "./reducers/incidentCategories";
import personnel from "./reducers/personnel";
import roles from "./reducers/roles";
import actions from "./reducers/auditActions";
import violationCategory from "./reducers/violationCategory";
export default configureStore({
  reducer: {
    user: userReducer,
    incidents: incidents,
    audits: audits,
    incidentTypes: incidentTypes,
    violations: violations,
    drivers: drivers,
    vehicles: vehicles,
    driverPaginated: driverPaginated,
    pagination: pagination,
    manageAudit: manageAudit,
    incidentCategories: IncidentCategories,
    personells: personnel,
    roles: roles,
    actions: actions,
    violationCategories: violationCategory,
  },
});
