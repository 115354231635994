import { createSlice } from "@reduxjs/toolkit";

const initialStateValue = {
  data: [],
  errMsg: "",
};

export const auditSlice = createSlice({
  name: "audit",
  initialState: { value: initialStateValue },
  reducers: {
    //actions
    getAudits: (state, action) => {
      state.value.data = action.payload;
    },
    setErrMsg: (state, action) => {
      state.value.errMsg = action.payload;
    },
    logoutAudits: (state) => {
      state.value = initialStateValue;
    },
  },
});
export const { getAudits, setErrMsg, logoutAudits } = auditSlice.actions;
// export const selectIncident = (state) => state.incidents;
export default auditSlice.reducer;
