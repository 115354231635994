import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  data: [],
  errMsg: "",
};
export const roleSlice = createSlice({
  name: "roles",
  initialState: { value: initialState },

  reducers: {
    getRoles: (state, action) => {
      state.value.data = action.payload;
    },
    logoutRoles: (state) => {
      state.value = initialState;
    },
    setErrMsg: (state, action) => {
      state.value.errMsg = action.payload;
    },
  },
});
export const { getRoles, logoutRoles, setErrMsg } = roleSlice.actions;
export const selectRole = (state) => state.roles;
export default roleSlice.reducer;
