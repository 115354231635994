import { createSlice } from "@reduxjs/toolkit";

const initialStateValue = {
  data: [],
  errMsg: "",
};

export const personellSlice = createSlice({
  name: "personell",
  initialState: { value: initialStateValue },
  reducers: {
    //actions
    getPersonnels: (state, action) => {
      state.value.data = action.payload;
    },
    setErrMsg: (state, action) => {
      state.value.errMsg = action.payload;
    },
    logoutPersonnels: (state) => {
      state.value = initialStateValue;
    },
  },
});
export const { getPersonnels, setErrMsg, logoutPersonnels } =
  personellSlice.actions;
export const selectPersonnel = (state) => state.personells;
export default personellSlice.reducer;
