import { Form } from "react-bootstrap";
import { useState, useEffect } from "react";
import axios from "../../utils/axios";
import { useSelector, useDispatch } from "react-redux";
import { getAudits } from "../../reducers/audit";

const EditAuditForm = (currentAudit) => {
  const dispatch = useDispatch();
  const audits = useSelector((state) => state.audits.value.data);
  const [types, setTypes] = useState();
  const [categorys, setCategory] = useState();
  const [statuses, setStatus] = useState();
  const [organizations, setOrganization] = useState([]);
  const [measures, setMeasure] = useState();
  const [errMsg, setErrMsg] = useState("");
  const [confMsg, setConfMsg] = useState("");
  const [allAudits, setAllAudits] = useState(audits);
  const [audit, setAudit] = useState(currentAudit.currentAudit);
  // console.log(audit);
  useEffect(() => {
    fetchAuditTypes();
    fetchAuditCategories();
    fetchAuditStatus();
    fetchAuditOrganization();
    fetchAuditMeasures();
  }, []);
  const fetchAuditTypes = () => {
    const url = "/audits/types";
    try {
      axios.get(url).then((res) => {
        setTypes(res.data);
      });
    } catch {
      setErrMsg("A network error occured");
    }
  };

  const fetchAuditCategories = () => {
    const url = "/audits/category";
    try {
      axios.get(url).then((res) => {
        setCategory(res.data);
      });
    } catch {
      setErrMsg("A network error occured");
    }
  };

  const fetchAuditStatus = () => {
    const url = "/audits/status";
    try {
      axios.get(url).then((res) => {
        setStatus(res.data);
      });
    } catch {
      setErrMsg("A network error occured");
    }
  };

  const fetchAuditMeasures = () => {
    const url = "/audits/measures";
    try {
      axios.get(url).then((res) => {
        setMeasure(res.data);
      });
    } catch {
      setErrMsg("A network error occured");
    }
  };

  const fetchAuditOrganization = () => {
    const url = "/audits/organizations";
    try {
      axios.get(url).then((res) => {
        setOrganization(res.data);
      });
    } catch (err) {
      setErrMsg("A network error occured");
    }
  };

  const handleAuditFormChange = (e) => {
    const updatedAudit = {};
    let auditAttribute = e.target.id;
    updatedAudit[auditAttribute] = e.target.value;
    if (auditAttribute === "audit_type_id") {
      updatedAudit["organizationRequiringAudit"] = "";
    }

    setAudit({ ...audit, [e.target.id]: e.target.value });
    setErrMsg("");
  };
  const getorganization = (id) => {
    let plate = "";
    organizations.forEach((category) => {
      if (category.id === id) {
        plate = category.name;
      }
    });
    return plate;
  };

  const handleAuditFormSubmit = (e) => {
    setErrMsg("");
    e.preventDefault();
    const url = `/audits/${audit.id}`;
    if (!audit.name.trim()) {
      setErrMsg("Audit name is required");
    } else if (!audit.audit_type_id) {
      setErrMsg("Audit type is required");
    } else if (!audit.due_date) {
      setErrMsg("Audit date is required");
    } else if (!audit.description.trim()) {
      setErrMsg("Audit description is required");
    } else if (audit.description.replaceAll("  ", "").length > 255) {
      setErrMsg(" Description is too long");
    } else if (!audit.organization_id && parseInt(audit.audit_type_id) !== 1) {
      setErrMsg("Audit organization is required");
    } else if (!audit.audit_measure_id) {
      setErrMsg("Audit measure is required");
    } else if (!audit.audit_category_id) {
      setErrMsg("Audit category is required");
    } else if (!audit.status) {
      setErrMsg("Audit status is required");
    } else if (audit?.notes?.replaceAll("  ", "").length > 255) {
      setErrMsg(" Notes is too long");
    } else {
      let filterInternal = organizations?.filter((org) =>
        org.name.toLowerCase().includes("pontypridd")
      );
      let filterIntenalType = types?.filter((type) =>
        type.name.includes("Internal")
      );
      // let internalType = filterIntenalType[0].id;
      // let internalOrganization = filterInternal[0].id;

      //   organization:
      //     parseInt(audit.audit_type_id) !== parseInt(internalType)
      //       ? audit.organizationRequiringAudit
      //       : internalOrganization,
      // console.log(newData);

      axios
        .patch(url, audit)
        .then((res) => {
          let allupdated = allAudits.map((single) =>
            single.id === audit.id ? (single = audit) : single
          );
          dispatch(getAudits(allupdated));
          setConfMsg("Audit Updated Successfully");
        })

        .catch((err) => {
          if (err.response.data !== undefined) {
            const error = String(err.response.data.error.error);
            setErrMsg(error);
          } else {
            setErrMsg("A network error occured");
          }
        });
    }
  };

  return (
    <form onSubmit={handleAuditFormSubmit}>
      <div className={confMsg ? "confmsg" : "offscreen"} aria-live="assertive">
        {confMsg}
      </div>
      <div className={errMsg ? "errmsg" : "offscreen"} aria-live="assertive">
        {errMsg}
      </div>
      <div className="">
        <div>
          <label htmlFor="name">Audit Name</label>
          <input
            type="text"
            className="form-control"
            id="name"
            value={audit.name}
            onChange={handleAuditFormChange}
            disabled
          />
        </div>
        <div className="">
          <label htmlFor="description">Audit description</label>
          <textarea
            type="text"
            className="form-control"
            id="description"
            onChange={handleAuditFormChange}
            value={audit.description}
          ></textarea>
        </div>
        <div>
          <label htmlFor="audit_type_id">Audit Type</label>
          <Form.Select
            id="audit_type_id"
            onChange={handleAuditFormChange}
            value={audit.audit_type_id}
            disabled
          >
            {types?.map((type) => {
              return (
                <option key={type.id} value={type.id}>
                  {type.name}
                </option>
              );
            })}
          </Form.Select>
        </div>
        {parseInt(audit.audit_type_id) !== 1 && (
          <div>
            <label htmlFor="organizationRequiringAudit">
              Organization requesting audit
            </label>
            <Form.Select
              id="organizationRequiringAudit"
              onChange={handleAuditFormChange}
              value={audit.organization_id}
              required
              disabled
            >
              <option>{getorganization(audit.organization_id)}</option>
              {/* 
              {parseInt(audit.audit_type_id) === 1
                ? null
                : organizations
                    ?.filter((org) => !org.name.includes("Ponty"))
                    .map((organization) => {
                      return (
                        <option key={organization.id} value={organization.id}>
                          {organization.name}
                        </option>
                      );
                    })} */}
            </Form.Select>
          </div>
        )}
        <div>
          <label htmlFor="audit_measure_id">Audit Measure</label>
          <Form.Select
            id="audit_measure_id"
            onChange={handleAuditFormChange}
            value={audit?.audit_measure_id || ""}
            disabled
          >
            <option>Select Measure</option>
            {measures?.map((measure) => {
              return (
                <option key={measure.id} value={measure.id}>
                  {measure.name}
                </option>
              );
            })}
          </Form.Select>
        </div>
        <div>
          <label htmlFor="audit_category_id">Audit Category</label>

          <Form.Select
            id="audit_category_id"
            onChange={handleAuditFormChange}
            value={audit.audit_category_id || ""}
            disabled
          >
            <option>Select Category</option>

            {categorys?.map((category) => {
              return (
                <option key={category.id} value={category.id}>
                  {category.name}
                </option>
              );
            })}
          </Form.Select>
        </div>
        <div>
          <label htmlFor="name">Raised By</label>
          <input
            type="text"
            className="form-control"
            id="raised_by"
            onChange={handleAuditFormChange}
            value={audit.raised_by}
            disabled
          />
        </div>
        <div>
          <label htmlFor="due_date">Date Raised</label>

          <input
            type="date"
            className="form-control"
            id="date_raised"
            onChange={handleAuditFormChange}
            value={audit.date_raised}
            disabled
          />
        </div>
        <div>
          <label htmlFor="due_date">Date Due</label>

          <input
            type="date"
            className="form-control"
            id="due_date"
            onChange={handleAuditFormChange}
            value={audit.due_date}
          />
        </div>
        <div>
          <label htmlFor="name">Assigned To</label>
          <input
            type="text"
            className="form-control"
            id="accountable"
            onChange={handleAuditFormChange}
            value={audit.accountable}
            disabled
          />
        </div>
        <div>
          <label htmlFor="status">Audit Status</label>
          <Form.Select
            id="status"
            onChange={handleAuditFormChange}
            value={audit.status}
          >
            {statuses?.map((status) => {
              return (
                <option key={status.id} value={status.id}>
                  {status.name}
                </option>
              );
            })}
          </Form.Select>
        </div>
        <div className="">
          <label htmlFor="auditNotes">Notes</label>
          <textarea
            className="form-control"
            id="notes"
            onChange={handleAuditFormChange}
            value={audit?.notes}
          ></textarea>
        </div>
      </div>
      <div>
        <input
          type="submit"
          className="form-control my-3 float-end btn btn-warning"
          value="update"
        />
      </div>
    </form>
  );
};

export default EditAuditForm;
