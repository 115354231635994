import React from "react";
import {
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  Bar,
  BarChart,
  Legend,
} from "recharts";

const SimpleLineGraph = ({ data, type }) => {
  let sum = 0;
  data.forEach((coun) => {
    sum < coun.count ? (sum = parseInt(coun.count)) : (sum = sum);
  });
  return (
    <div style={{ width: "100%" }}>
      <ResponsiveContainer width="100%" height={300}>
        <BarChart data={data} syncId={type} margin={3}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="name" />
          <YAxis type="number" domain={[0, sum]} />
          <Tooltip />
          <Legend />
          <Bar
            type="monotone"
            dataKey="count"
            stroke="#ef7f1a"
            fill="#7a0d53"
          />
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
};
export default SimpleLineGraph;
